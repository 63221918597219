export type Medication = {
  medicationName: string;
  dosingSchedule?: string;
  commentOnDosingSchedule?: string;
  comment?: string;
  from?: Date;
  until?: Date;
};

export function medicationToTextMessage(medication: Medication): string {
  let medicationText = `*${medication.medicationName}*\n`;
  const medicationDuration = getMedicationDuration(medication);
  if (medicationDuration) {
    medicationText += `- _Dauer von - bis:_ ${medicationDuration}\n`;
  }
  if (medication.dosingSchedule) {
    medicationText += `- _Einnameschema (Mo-Mi-Ab-Na):_ ${medication.dosingSchedule}\n`;
  }
  if (medication.commentOnDosingSchedule) {
    medicationText += `- _Bemerkung Einnahmeschema:_ ${medication.commentOnDosingSchedule}\n`;
  }
  if (medication.comment) {
    medicationText += `- _Bemerkung Allgemein:_ ${medication.comment}\n`;
  }

  return medicationText;
}

export function getMedicationDuration(medication: Medication): string | undefined {
  const from = medication.from?.toLocaleDateString('de-CH');
  const until = medication.until?.toLocaleDateString('de-CH');
  if (!from && !until) {
    return undefined;
  } else if (from && until) {
    return `${from} - ${until}`;
  }
  return !until ? `${from} - ` : ` - ${until}`;
}
