import { connect } from 'react-redux';
import { RootState } from '../../store';
import { selectCurrentPatientId } from '../../store/patient-list/selector';
import Medication from './Medication';
import { selectMedications } from '../../store/heureka/selector';
import { Action, Dispatch } from 'redux';
import { pipe } from 'ramda';
import { setTextForChat } from '../../store/chats/action';
import { selectTextForChat } from '../../store/chats/selector';

const mapStateToProps = (state: RootState) => ({
  medications: selectMedications(state),
  patientId: selectCurrentPatientId(state),
  textForChat: selectTextForChat(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setText: pipe(setTextForChat, dispatch),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
export type Props = Readonly<StateProps & DispatchProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Medication);
