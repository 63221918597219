import { createReducer } from 'deox';
import { HeurekaPatients } from '../../types/heurekaPatient';
import { setHeurekaPatients, setMedications } from './actions';
import { Medication } from '../../types/medication';

const initialState: Readonly<{
  heurekaPatients: HeurekaPatients;
  medications?: Medication[];
}> = {
  heurekaPatients: [],
  medications: [],
};

export const heurekaReducer = createReducer(initialState, (handleAction) => [
  handleAction(setHeurekaPatients, (state, { payload }) => ({
    ...state,
    heurekaPatients: payload.heurekaPatients,
  })),
  handleAction(setMedications, (state, { payload }) => ({
    ...state,
    medications: payload.medications,
  })),
]);
