import { SidebarComponentTitleText } from './SidebarComponentTitleText';
import { Box } from '@mui/material';

export default function SidebarComponentTitle({ children }: { children: string }) {
  return (
    <Box display="flex" alignItems="center" mb={2}>
      <SidebarComponentTitleText>{children}</SidebarComponentTitleText>
    </Box>
  );
}
