import { createActionCreator } from 'deox';
import { Message } from '../../types/message';
import { createRequestActionsCreator } from '@healthinal/typescript-base-lib';

export const setTextForChat = createActionCreator(
  'chats/SET_TEXT',
  (resolve) => (patientId: string, text: string) => resolve({ patientId, text }),
);

export const addMessages = createActionCreator(
  'chats/ADD_MESSAGES',
  (resolve) => (patientId: string, messages: Message[]) => resolve({ patientId, messages }),
);

export const addThumbnailUrl = createActionCreator(
  'chats/ADD_THUMBNAIL',
  (resolve) => (messageId: string, thumbnailUrl: string) => resolve({ messageId, thumbnailUrl }),
);

export const clearThumbnailUrls = createActionCreator(
  'chats/CLEAR_THUMBNAILS',
  (resolve) => (patientId: string) => resolve({ patientId }),
);

const createRequestActions = createRequestActionsCreator('chats');

export const requestActionBundles = {
  fetchThumbnail: createRequestActions(
    'FETCH_THUMBNAIL',
    (resolve) => (organizationId: string, patientId: string, messageId: string) =>
      resolve({
        organizationId,
        patientId,
        messageId,
      }),
  ),
};
