import { endpoints } from '../api';
import { call, put, takeEvery } from 'typed-redux-saga/macro';
import { requestActionBundles, addThumbnailUrl } from './action';
import { ActionType } from 'deox';

function* requestSaga({
  payload: { organizationId, patientId, messageId },
}: ActionType<typeof requestActionBundles.fetchThumbnail.sendRequest>) {
  try {
    const { data } = yield* call(endpoints.message.fetchThumbnail, organizationId, patientId, messageId);

    yield* put(addThumbnailUrl(messageId, URL.createObjectURL(data)));
    yield* put(requestActionBundles.fetchThumbnail.requestSucceeded());
  } catch (error) {
    console.error('fetch messages request failed', error);
    yield* put(requestActionBundles.fetchThumbnail.requestFailed());
  }
}

export function* thumbnailFetchSaga() {
  yield* takeEvery(requestActionBundles.fetchThumbnail.sendRequest, requestSaga);
}
