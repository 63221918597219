import { RootState } from '../index';
import { pipe } from 'ramda';

const selectPatientList = ({ patientList }: RootState) => patientList;

export const selectCurrentPatientId = pipe(selectPatientList, ({ currentPatientId }) => currentPatientId);

export const selectCurrentPatientIdOrThrow = pipe(selectPatientList, ({ currentPatientId }) => {
  if (!currentPatientId) {
    throw Error('No currentPatientId');
  }
  return currentPatientId;
});

export const selectPreviousPatientId = pipe(selectPatientList, ({ previousPatientId }) => previousPatientId);

export const selectPatientSearchText = pipe(selectPatientList, ({ patientSearchText }) => patientSearchText);
