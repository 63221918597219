import PatientDetails from './PatientDetails';
import { RootState } from '../../store';
import { Action, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { selectIsLoadingPatientDetails, selectPatientDetails } from '../../store/data/selector';
import { requestActionBundles } from '../../store/data/action';
import { pipe } from 'ramda';

const mapStateToProps = (state: RootState) => ({
  patientDetails: selectPatientDetails(state),
  patientDetailsLoading: () => selectIsLoadingPatientDetails(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  patchPatientDetails: pipe(requestActionBundles.patchPatientDetails.sendRequest, dispatch),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
export type Props = Readonly<StateProps & DispatchProps>;

export default connect(mapStateToProps, mapDispatchToProps)(PatientDetails);
