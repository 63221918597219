import { Box, IconButton, Skeleton, styled } from '@mui/material';
import { useEffect } from 'react';
import DownloadButton from './shared/DownloadButtonContainer';
import { Props } from './FileMessageImageContainer';
import MessageBase from './shared/MessageBase';
import Time from './shared/Time';
import { getFileName } from '../../../utils/getFileName';

const MAX_RATIO = 1.375; // from Whatsapp

const ImageBox = styled(Box)`
  display: flex;
  align-items: start;
  justify-content: end;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export default function FileMessageImage({
  message,
  organizationId,
  patientId,
  patientDetails,
  showTail,
  thumbnailUrl,
  loadThumbnail,
  viewItem,
  isLast,
}: Props) {
  const {
    text,
    id,
    sentByPatient,
    authoredDate,
    thumbnailHeight: height,
    thumbnailWidth: width,
    fileMimeType,
  } = message;

  if (!height || !width) {
    throw Error(`No thumbnail size for thumbnail o.O, message: ${id}`);
  }

  useEffect(() => {
    if (!thumbnailUrl && patientId) {
      loadThumbnail(organizationId, patientId, id);
    }
  }, [id, loadThumbnail, organizationId, patientId, thumbnailUrl]);

  const isVerticalImage = height > width;
  const sourceImageRatio = Math.max(height, width) / Math.min(height, width);
  const ratio = Math.min(MAX_RATIO, sourceImageRatio);
  const displayWidth = isVerticalImage ? Math.round((1 / ratio) * 330) : 330;
  const displayHeight = (isVerticalImage ? ratio : 1 / ratio) * displayWidth;
  const fileName = getFileName(message, patientDetails);

  return (
    <MessageBase sentByPatient={sentByPatient} showTail={showTail} isLast={isLast}>
      <Box width={displayWidth}>
        {thumbnailUrl ? (
          <ImageBox
            onClick={() => viewItem({ id, mimeType: fileMimeType, fileName })}
            role="button"
            width={displayWidth}
            height={displayHeight}
            sx={{ backgroundImage: `url(${thumbnailUrl})` }}
            p={1}>
            <DownloadButton
              message={message}
              Component={({ download, icon }) => (
                <IconButton size="small" onClick={download}>
                  {icon}
                </IconButton>
              )}
            />
          </ImageBox>
        ) : (
          <Skeleton variant="rectangular" width={displayWidth} height={displayHeight} />
        )}
        <div>
          {text}
          <Time dateTime={authoredDate} />
        </div>
      </Box>
    </MessageBase>
  );
}
