import { RootState } from '../index';
import { pipe } from 'ramda';

const selectFileViewer = ({ fileViewer }: RootState) => fileViewer;

export const selectItem = pipe(selectFileViewer, ({ item }) => item);
export const selectIsLoading = pipe(selectFileViewer, ({ isLoading }) => isLoading);
export const selectLoaded = pipe(selectFileViewer, ({ loaded }) => loaded);
export const selectImage = pipe(selectFileViewer, ({ image }) => image);
export const selectRotation = pipe(selectFileViewer, ({ rotation }) => rotation);
