import { Box, BoxProps, useTheme } from '@mui/material';
import { ReactNode } from 'react';

export default function MessageBase({
  children,
  sentByPatient,
  showTail,
  isLast,
  sx,
}: {
  children: ReactNode;
  sentByPatient: boolean;
  showTail: boolean;
  isLast: boolean;
  sx?: BoxProps['sx'];
}) {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      justifyContent={sentByPatient ? 'flex-start' : 'flex-end'}
      pt={showTail ? 1.5 : 0}
      pb={isLast ? 2 : 0.625}
      mx={4}>
      <Box
        boxShadow={theme.shadows[1]}
        display="flex"
        p={1}
        sx={{ backgroundColor: sentByPatient ? 'white' : theme.palette.green[200], whiteSpace: 'pre-wrap', ...sx }}
        borderRadius={`${sentByPatient && showTail ? 0 : 12}px ${!sentByPatient && showTail ? 0 : 12}px 12px 12px`}>
        {children}
      </Box>
    </Box>
  );
}
