import { endpoints } from '../api';
import { call, cancel, cancelled, fork, put, select, take, takeLatest } from 'typed-redux-saga/macro';
import { close, requestActionBundles, setImage, setIsLoading, setItem, setLoaded } from './action';
import { selectCurrentPatientIdOrThrow } from '../patient-list/selector';
import { selectOrganizationId } from '../data/selector';
import { selectImage, selectItem } from './selector';
import { getImageInfo, ImageInfo } from '../../utils/getImageInfo';
import { channel, Task } from 'redux-saga';
import { Action } from 'redux';
import { AxiosProgressEvent } from 'axios';

const loadedChannel = channel<number>();

function* requestSaga() {
  const abortController = new AbortController();
  let objectUrl: string | undefined = undefined;
  try {
    yield* put(setIsLoading(true));
    yield* put(setLoaded(0));

    const organizationId: string = yield* select(selectOrganizationId);
    const patientId = yield* select(selectCurrentPatientIdOrThrow);
    const item = yield* select(selectItem);

    if (!item) {
      throw new Error('No viewer item!');
    }

    const { data } = yield* call(
      endpoints.message.fetchFile,
      organizationId,
      patientId,
      item.id,
      ({ loaded, total }: AxiosProgressEvent) => loadedChannel.put(total ? loaded / total : 0),
      abortController,
    );
    objectUrl = URL.createObjectURL(data);
    yield* put(setImage(yield* call(getImageInfo, objectUrl)));
    yield* put(setIsLoading(false));
    yield* put(requestActionBundles.fetchFile.requestSucceeded());
  } catch (error) {
    console.error('fetch message file for viewer request failed', error);
    yield* put(requestActionBundles.fetchFile.requestFailed());
  } finally {
    if (yield* cancelled()) {
      abortController.abort();
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    }
  }
}

function* updateLoadedNumber() {
  while (true) {
    const loaded: number = yield* take(loadedChannel);
    yield* put(setLoaded(loaded));
  }
}

function* dispatchSaga() {
  yield* put(requestActionBundles.fetchFile.sendRequest());
}

export function* fileViewerFetchSaga() {
  // start requestSaga on fetchFile.sendRequest
  // takeLatest from https://redux-saga.js.org/docs/advanced/Concurrency with exposed lastTask
  let lastTask: Task | undefined;
  yield* fork(function* () {
    while (true) {
      const action: Action = yield* take(requestActionBundles.fetchFile.sendRequest);
      if (lastTask) {
        yield* cancel(lastTask);
      }
      lastTask = yield* fork(requestSaga as (...args: unknown[]) => unknown, action);
    }
  });
  // cancel lastTask and revoke image cache on close action
  yield* fork(function* () {
    while (true) {
      yield* take(close);
      if (lastTask) {
        yield* cancel(lastTask);
      }
      const image: ImageInfo | undefined = yield* select(selectImage);
      if (image) {
        URL.revokeObjectURL(image.url);
      }
    }
  });
  yield* fork(updateLoadedNumber);
  yield* takeLatest(setItem, dispatchSaga);
}
