import { endpoints } from '../api';
import { call, put, select, takeLatest } from 'typed-redux-saga/macro';
import { requestActionBundles } from './action';
import { selectCurrentPatientIdOrThrow } from '../patient-list/selector';
import { selectOrganizationId } from './selector';
import { addMessages } from '../chats/action';
import { convertMessage } from '../chats/convertUtils';

function* requestSaga() {
  try {
    const organizationId = yield* select(selectOrganizationId);
    const patientId = yield* select(selectCurrentPatientIdOrThrow);
    const {
      data: { items },
    } = yield* call(endpoints.message.fetchAll, organizationId, patientId);

    yield* put(
      addMessages(
        patientId,
        items.map((message) => convertMessage(message, patientId)),
      ),
    );
    yield* put(requestActionBundles.fetchMessages.requestSucceeded());
  } catch (error) {
    console.error('fetch messages request failed', error);
    yield* put(requestActionBundles.fetchMessages.requestFailed());
  }
}

export function* messageFetchSaga() {
  yield* takeLatest(requestActionBundles.fetchMessages.sendRequest, requestSaga);
}
