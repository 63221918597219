import { Box, Button, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import MedicationAccordion from './MedicationAccordion';
import { Props } from './MedicationsContainer';
import { medicationToTextMessage } from '../../types/medication';

export default function Medication({ medications, patientId, setText, textForChat }: Props) {
  if (!medications?.length || !patientId) {
    return null;
  }

  return (
    <Box p={3}>
      <Typography variant="h6" sx={{ fontWeight: 600, fontSize: 17, mb: 2 }}>
        Aktuelle Medikation:
      </Typography>
      {medications.map((m, index) => (
        <MedicationAccordion key={index} medication={m} />
      ))}
      <Box pt={2} display={'flex'} justifyContent={'end'}>
        <Button
          variant="contained"
          type="submit"
          startIcon={<SendIcon />}
          onClick={() => {
            if (
              !textForChat ||
              textForChat.length === 0 ||
              window.confirm('Es befindet sich bereits ein Text im Eingabefeld, wollen Sie diesen überschreiben?')
            ) {
              setText(
                patientId,
                `*Aktuelle Medikation (${new Date().toLocaleDateString()}):* \n\n ${medications
                  ?.map((medication) => medicationToTextMessage(medication))
                  .join('\n')}`,
              );
            }
          }}>
          Medikation senden
        </Button>
      </Box>
    </Box>
  );
}
