import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { Box, Fab, styled } from '@mui/material';
import { Props } from './ChatContainer';
import GenericMessage from './Messages/Message';
import DateBubble from './DateBubble';
import FileEditor from './FileEditor/FileEditor';
import { useFileUpload } from '../../hooks/useFileUpload';
import { useDropzone } from '../../hooks/useDropzone';
import ChatInputContainer from './ChatInput/ChatInputContainer';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import theme from '../../theme';
import EmptyState from './EmptyState/EmptyState';
import { convertMessagesIntoChatItems } from './convertMessagesIntoChatItems';

const Dropzone = styled(Box)`
  display: flex;
  flex: 1;
  justify-content: center;
  align-content: center;
  align-items: center;
  border: 3px dashed #ccc;
`;

const MessageList = styled(Box)`
  flex: 1;
  position: relative;
`;

const Item = styled('div')`
  display: grid;
`;

const ScrollDownFab = styled(Fab)`
  position: absolute;
  right: ${theme.spacing(2)};
  bottom: ${theme.spacing(1.5)};
  background-color: white;
  color: ${theme.palette.action.active};
`;

export default function Chat({ messages, currentPatientId }: Props) {
  const virtuoso = useRef<VirtuosoHandle>(null);
  const [atBottom, setAtBottom] = useState(true);
  const chatItems = useMemo(() => convertMessagesIntoChatItems(messages), [messages]);
  const lastChatItemIndex = chatItems.length - 1;
  const { files, addFiles, clearFiles } = useFileUpload();
  const [showDropzone, dropzoneProps] = useDropzone(addFiles);

  // clearFiles is not guaranteed to be stable
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(clearFiles, [currentPatientId]);

  // only scroll to bottom if patient changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => virtuoso?.current?.scrollToIndex(lastChatItemIndex), [currentPatientId]);

  const scrollToMessage = useCallback(
    (messageId: string) => {
      const chatItemIndex = chatItems.findIndex((item) => item.type === 'message' && item.message?.id === messageId);
      if (chatItemIndex) {
        virtuoso?.current?.scrollToIndex(chatItemIndex);
      }
    },
    [chatItems, virtuoso],
  );

  if (!currentPatientId) {
    return <EmptyState />;
  }

  return (
    <Box display="flex" flexDirection="column" gridColumn={1} gridRow={1} {...dropzoneProps}>
      {showDropzone && <Dropzone m={2}>Datei hierhin ziehen</Dropzone>}
      {files.length > 0 && <FileEditor visible={!showDropzone} files={files} clearFiles={clearFiles} />}
      <MessageList display={showDropzone || files.length ? 'none' : 'flex'}>
        {chatItems.length > 0 && (
          <>
            <Virtuoso
              ref={virtuoso}
              style={{ width: '100%', height: '100%' }}
              data={chatItems}
              itemContent={(index, chatItem) =>
                chatItem.type === 'message' ? (
                  <GenericMessage
                    message={chatItem.message}
                    key={chatItem.message.id}
                    showTail={chatItem.showTail}
                    scrollToMessage={scrollToMessage}
                    isLast={index + 1 === chatItems.length}
                  />
                ) : (
                  <DateBubble date={chatItem.date} key={chatItem.date.valueOf()} />
                )
              }
              initialTopMostItemIndex={lastChatItemIndex}
              atBottomStateChange={setAtBottom}
              followOutput={'auto'}
              components={{ Item }}
            />
            {!atBottom && (
              <ScrollDownFab
                size="small"
                aria-label="Zur letzten Nachricht"
                onClick={() => virtuoso?.current?.scrollToIndex({ index: lastChatItemIndex, behavior: 'smooth' })}>
                <ArrowDownwardIcon />
              </ScrollDownFab>
            )}
          </>
        )}
      </MessageList>
      <ChatInputContainer
        files={files}
        addFiles={addFiles}
        clearFiles={clearFiles}
        currentPatientId={currentPatientId}
      />
    </Box>
  );
}
