import { endpoints } from '../api';
import { ActionType } from 'deox';
import { call, put, select, takeLatest } from 'typed-redux-saga/macro';
import { requestActionBundles, setPatientDetails } from './action';
import { selectCurrentPatientIdOrThrow } from '../patient-list/selector';
import { selectOrganizationId } from './selector';
import { convertPatientDetails } from './convertUtils';

function* requestSaga({ payload }: ActionType<typeof requestActionBundles.patchPatientDetails.sendRequest>) {
  try {
    const organizationId = yield* select(selectOrganizationId);
    const patientId = yield* select(selectCurrentPatientIdOrThrow);
    const { data } = yield* call(endpoints.patient.patch, organizationId, patientId, payload);

    yield* put(setPatientDetails(convertPatientDetails(data)));
    yield* put(requestActionBundles.patchPatientDetails.requestSucceeded());
  } catch (error) {
    console.error('send message request failed', error);
    yield* put(requestActionBundles.patchPatientDetails.requestFailed());
  }
}

export function* patientDetailsPatchSaga() {
  yield* takeLatest(requestActionBundles.patchPatientDetails.sendRequest, requestSaga);
}
