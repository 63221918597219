import { RootState } from '../../../store';
import { connect } from 'react-redux';
import { FileMessage as FileMessageType } from '../../../types/message';
import FileMessageImage from './FileMessageImage';
import { selectOrganizationId, selectPatientDetails } from '../../../store/data/selector';
import { selectCurrentPatientId } from '../../../store/patient-list/selector';
import { selectThumbnailUrl } from '../../../store/chats/selector';
import { Action, Dispatch } from 'redux';
import { pipe } from 'ramda';
import { requestActionBundles } from '../../../store/chats/action';
import { setItem } from '../../../store/file-viewer/action';

const mapStateToProps = (state: RootState, { message }: OwnProps) => ({
  organizationId: selectOrganizationId(state),
  patientId: selectCurrentPatientId(state),
  patientDetails: selectPatientDetails(state),
  thumbnailUrl: selectThumbnailUrl(message.id)(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  loadThumbnail: pipe(requestActionBundles.fetchThumbnail.sendRequest, dispatch),
  viewItem: pipe(setItem, dispatch),
});

type OwnProps = { message: FileMessageType; showTail: boolean; isLast: boolean };
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
export type Props = Readonly<OwnProps & StateProps & DispatchProps>;

export default connect(mapStateToProps, mapDispatchToProps)(FileMessageImage);
