import { createReducer } from 'deox';
import {
  requestFailedActions,
  requestSucceededActions,
  sendRequestActions,
  setOrganization,
  setOrganizationId,
  setPatientDetails,
  setPatients,
} from './action';
import { applyPipe, removeRequestSuffixFromActionType } from '@healthinal/typescript-base-lib';
import { indexOf, remove } from 'ramda';
import { setCurrentPatientId } from '../patient-list/action';
import { CompactPatients, CompletePatient } from '../../types/patients';
import { format } from '../../utils/date';
import { Organization } from '../../types/organization';
import { HeurekaPatients } from '../../types/heurekaPatient';

export const initialState: Readonly<{
  organizationId: string;
  patients: CompactPatients;
  heurekaPatients: HeurekaPatients;
  patientDetails?: CompletePatient;
  organization?: Organization;
  requestsRunning: readonly string[];
}> = {
  organizationId: '',
  patients: [],
  organization: undefined,
  heurekaPatients: [],
  requestsRunning: [], //TODO: separate request handling into separate state. move it into base lib. see: https://github.com/healthinal/covid-form-prototype/blob/develop/frontend/src/store/requests/reducer.ts#L1
};

//TODO: move function to base library
const removeFirstAppearanceOfType = (x: string, xs: readonly string[]) =>
  applyPipe(indexOf(removeRequestSuffixFromActionType(x), xs), (index) => (index >= 0 ? remove(index, 1, xs) : xs));

export const dataReducer = createReducer(initialState, (handleAction) => [
  handleAction(setOrganizationId, (state, { payload }) => ({
    ...state,
    organizationId: payload.organizationId,
  })),
  handleAction(setPatients, (state, { payload }) => ({
    ...state,
    patients: payload.patients.map((patient) => ({
      ...patient,
      dateOfBirth: patient.dateOfBirth && format(new Date(patient.dateOfBirth), 'dd.MM.yyyy'),
    })),
  })),
  handleAction(setPatientDetails, (state, { payload }) => ({
    ...state,
    patientDetails: payload.patientDetails,
  })),
  handleAction(setOrganization, (state, { payload }) => ({
    ...state,
    organization: payload.organization,
  })),
  handleAction(setCurrentPatientId, (state, { payload }) => ({
    ...state,
    patients: state.patients.map((patient) =>
      patient.id === payload.patientId ? { ...patient, unreadMessagesCount: 0 } : patient,
    ),
  })),
  handleAction(sendRequestActions, (state, action) => ({
    ...state,
    requestsRunning: [...state.requestsRunning, removeRequestSuffixFromActionType(action.type)],
  })),
  handleAction([...requestSucceededActions, ...requestFailedActions], (state, action) => ({
    ...state,
    requestsRunning: removeFirstAppearanceOfType(action.type, state.requestsRunning),
  })),
]);
