import { all } from 'typed-redux-saga/macro';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { dataReducer } from './data/reducer';
import { patientsFetchSaga } from './data/patients-fetch-saga';
import { patientListReducer } from './patient-list/reducer';
import { patientDetailsFetchSaga } from './data/patient-details-fetch-saga';
import { messageFetchSaga } from './data/messages-fetch-saga';
import { messageSendSaga } from './data/message-send-saga';
import { patientDetailsPatchSaga } from './data/patient-details-patch-saga';
import { chatsReducer } from './chats/reducer';
import { initialSaga } from './data/initial-saga';
import { newMessageSaga } from './data/new-message-saga';
import { fileViewerReducer } from './file-viewer/reducer';
import { fileViewerFetchSaga } from './file-viewer/file-viewer-fetch-saga';
import { thumbnailFetchSaga } from './chats/thumbnail-fetch-saga';
import { thumbnailReleaseSaga } from './chats/thumbnail-release-saga';
import { selectPatientSaga } from './patient-list/select-patient-saga';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { organizationFetchSaga } from './data/organization-fetch-saga';
import { heurekaPatientsFetchSaga } from './heureka/heureka-patients-fetch-saga';
import { linkHeurekaSaga } from './heureka/link-heureka-saga';
import { connectHeurekaSaga } from './data/connect-heureka-saga';
import { medicationsFetchSaga } from './heureka/medication-statements-fetch-saga';
import { heurekaReducer } from './heureka/reducer';

export const rootReducer = combineReducers({
  data: dataReducer,
  patientList: patientListReducer,
  heureka: heurekaReducer,
  chats: chatsReducer,
  fileViewer: fileViewerReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;

export function* rootSaga() {
  yield* all([
    patientsFetchSaga(),
    medicationsFetchSaga(),
    heurekaPatientsFetchSaga(),
    patientDetailsFetchSaga(),
    organizationFetchSaga(),
    patientDetailsPatchSaga(),
    linkHeurekaSaga(),
    connectHeurekaSaga(),
    messageFetchSaga(),
    messageSendSaga(),
    fileViewerFetchSaga(),
    newMessageSaga(),
    selectPatientSaga(),
    thumbnailFetchSaga(),
    thumbnailReleaseSaga(),
    initialSaga(),
  ]);
}

export default function setupStore() {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));
  sagaMiddleware.run(rootSaga);

  return store;
}
