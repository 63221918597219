import { identity } from 'ramda';
import { FileMessage } from '../types/message';
import { CompletePatient } from '../types/patients';
import { format } from './date';
import { MIME_TYPES_TO_EXTENSION } from './mimeTypesToExtension';

export const getFileName = ({ fileName, authoredDate, fileMimeType }: FileMessage, patient?: CompletePatient) =>
  fileName ??
  `${[format(authoredDate, 'yyyy-MM-dd_HH-mm'), patient?.firstName, patient?.lastName]
    .filter(identity)
    .join('_')}.${MIME_TYPES_TO_EXTENSION.get(fileMimeType)}`;
