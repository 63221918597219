import { Box } from '@mui/material';
import theme from '../../../../theme';
import { format } from '../../../../utils/date';

export default function Time({ dateTime }: { dateTime: number }) {
  return (
    <Box
      mt={1}
      mb={-1}
      ml={2}
      sx={{ ...theme.typography.caption, lineHeight: '20px', float: 'right', color: theme.palette.grey[800] }}>
      {format(dateTime, 'kk:mm')}
    </Box>
  );
}
