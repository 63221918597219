import { createRequestActionsCreator } from '@healthinal/typescript-base-lib';
import { createActionCreator } from 'deox';
import { ImageInfo } from '../../utils/getImageInfo';
import { ViewerItem } from './reducer';

export const close = createActionCreator('viewer/CLOSE');

export const setItem = createActionCreator('viewer/SET_ITEM', (resolve) => (payload: ViewerItem) => resolve(payload));

export const setIsLoading = createActionCreator(
  'viewer/SET_IS_LOADING',
  (resolve) => (payload: boolean) => resolve(payload),
);

export const setLoaded = createActionCreator('viewer/SET_LOADED', (resolve) => (payload: number) => resolve(payload));

export const setImage = createActionCreator('viewer/SET_IMAGE', (resolve) => (payload: ImageInfo) => resolve(payload));

export const setRotation = createActionCreator(
  'viewer/SET_ROTATION',
  (resolve) => (payload: number) => resolve(payload),
);

const createRequestActions = createRequestActionsCreator('viewer');

export const requestActionBundles = {
  fetchFile: createRequestActions('FETCH_FILE'),
};
