import { Box, Typography } from '@mui/material';
import { isImage } from '../../../../utils/typeExtensionFunctions';
import { FileMessage } from '../../../../types/message';
import QuotedFileMessageImageContainer from './QuotedFileMessageImageContainer';

export default function QuotedFileMessage({ quotedMessage }: { quotedMessage: FileMessage }) {
  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      {isImage(quotedMessage.fileMimeType) ? (
        <QuotedFileMessageImageContainer quotedMessage={quotedMessage} />
      ) : (
        <Box display="flex" flexDirection="row" pr={1} alignSelf="flex-end">
          <Typography sx={{ fontSize: 'small' }}>{quotedMessage.fileName}</Typography>
        </Box>
      )}
    </Box>
  );
}
