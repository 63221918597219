import { Action, Dispatch } from 'redux';
import { pipe } from 'ramda';
import { connect } from 'react-redux';
import { RootState } from '../../../../store';
import { selectOrganizationId } from '../../../../store/data/selector';
import { selectCurrentPatientId } from '../../../../store/patient-list/selector';
import { selectThumbnailUrl } from '../../../../store/chats/selector';
import { requestActionBundles } from '../../../../store/chats/action';
import { FileMessage } from '../../../../types/message';
import { QuotedFileMessageImage } from './QuotedFileMessageImage';

const mapStateToProps = (state: RootState, { quotedMessage }: OwnProps) => ({
  organizationId: selectOrganizationId(state),
  patientId: selectCurrentPatientId(state),
  thumbnailUrl: selectThumbnailUrl(quotedMessage.id)(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  loadThumbnail: pipe(requestActionBundles.fetchThumbnail.sendRequest, dispatch),
});

type OwnProps = { quotedMessage: FileMessage };
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
export type Props = Readonly<OwnProps & StateProps & DispatchProps>;

export default connect(mapStateToProps, mapDispatchToProps)(QuotedFileMessageImage);
