import { requestActionBundles, setPatients } from './action';
import { call, put, select, takeLatest } from 'typed-redux-saga/macro';
import { endpoints } from '../api';
import { selectOrganizationId } from './selector';
import { heurekaRequestActionBundles } from '../heureka/actions';

function* requestSaga() {
  try {
    const organizationId = yield* select(selectOrganizationId);
    const { data } = yield* call(endpoints.patient.fetchAll, organizationId);

    yield* put(setPatients(data.items));
    yield* put(requestActionBundles.fetchPatients.requestSucceeded());
  } catch (error) {
    console.error('fetch patients request failed', error);
    yield* put(requestActionBundles.fetchPatients.requestFailed());
  }
}

export function* patientsFetchSaga() {
  yield* takeLatest(requestActionBundles.fetchPatients.sendRequest, requestSaga);
  yield* takeLatest(requestActionBundles.patchPatientDetails.requestSucceeded, requestSaga);
  yield* takeLatest(heurekaRequestActionBundles.linkHeureka.requestSucceeded, requestSaga);
}
