import { createReducer } from 'deox';
import { setCurrentPatientId, setPatientSearchText } from './action';
import produce from 'immer';

const initialState: Readonly<{
  currentPatientId?: string;
  previousPatientId?: string;
  patientSearchText: string;
}> = {
  patientSearchText: '',
};

export const patientListReducer = createReducer(initialState, (handleAction) => [
  handleAction(setCurrentPatientId, (state, { payload: { patientId } }) =>
    produce(state, (draft) => {
      if (state.currentPatientId === patientId) {
        throw Error('Current patient already set.');
      }

      draft.previousPatientId = state.currentPatientId;
      draft.currentPatientId = patientId;
    }),
  ),
  handleAction(setPatientSearchText, (state, { payload: { searchText } }) =>
    produce(state, (draft) => {
      draft.patientSearchText = searchText;
    }),
  ),
]);
