import { requestActionBundles, startListeningForChatNotifications } from './action';
import { call, put, select, take, delay } from 'typed-redux-saga/macro';
import { selectOrganizationId } from './selector';
import { getToken } from '../../keycloak';
import { EventChannel, eventChannel } from 'redux-saga';
import { selectCurrentPatientId } from '../patient-list/selector';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { MessageNotificationDTO } from '../../types/dto/message';

const MAX_RETRIES = 10;

type NewMessageEvent = {
  payload?: MessageNotificationDTO;
};

const createNewMessageEventChannel: (organizationId: string, token: string) => EventChannel<NewMessageEvent | Error> = (
  organizationId: string,
  token: string,
) =>
  eventChannel((emit) => {
    const eventSource = new EventSourcePolyfill(`api/organization/${organizationId}/message-notification`, {
      headers: { Authorization: `Bearer ${token}` },
      heartbeatTimeout: Number.MAX_SAFE_INTEGER,
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument
    eventSource.onmessage = (event) => emit({ payload: JSON.parse(event.data) });
    eventSource.onerror = (error) => {
      console.error('Event source returns error', error);
      emit(Error());
    };
    return () => {
      eventSource.close();
    };
  });

export function* newMessageSaga() {
  yield* take(startListeningForChatNotifications);
  const organizationId: ReturnType<typeof selectOrganizationId> = yield* select(selectOrganizationId);
  let channel = createNewMessageEventChannel(organizationId, yield* call(getToken));
  let retryCount = 0;

  while (true) {
    try {
      // emitted errors will be thrown, source: https://github.com/redux-saga/redux-saga/issues/472#issuecomment-238847153
      const result = (yield* take(channel)) as NewMessageEvent;
      retryCount = 0;
      const currentUserId = yield* select(selectCurrentPatientId);
      // TODO: optimize both fetch requests and prevent full fetch
      if (currentUserId === result.payload?.patientId) {
        yield* put(requestActionBundles.fetchMessages.sendRequest());
      }
      yield* put(requestActionBundles.fetchPatients.sendRequest());
    } catch (e) {
      channel.close();
      if (retryCount < MAX_RETRIES) {
        yield* delay(retryCount * 500);
        channel = createNewMessageEventChannel(organizationId, yield* call(getToken));
        retryCount++;
      } else {
        //TODO: use snackbar instead
        if (window.confirm('Es ist ein Fehler aufgetreten. Die Seite wird erneut geladen.')) {
          window.location.reload();
        }
        return;
      }
    }
  }
}
