import { Box, Typography } from '@mui/material';

export default function MedicationDetails({ title, details }: { title: string; details?: string }) {
  if (!details) {
    return null;
  }
  return (
    <Box>
      <Typography variant={'caption'}>{title}</Typography>
      <Typography pl={1} variant={'body1'}>
        {details}
      </Typography>
    </Box>
  );
}
