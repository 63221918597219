import { CompactPatient } from '../types/patients';

const getFirstCodePointAsString = (value: string) => {
  const codePoint = value.codePointAt(0);
  if (codePoint) {
    return String.fromCodePoint(codePoint);
  } else {
    throw Error('Failed getting the first codepoint');
  }
};

export const abbreviatePatientName = (patient: CompactPatient) => {
  if (patient.firstName && patient.lastName) {
    return getFirstCodePointAsString(patient.firstName) + getFirstCodePointAsString(patient.lastName);
  }
};
