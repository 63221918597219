import { createRequestActionsCreator } from '@healthinal/typescript-base-lib';
import { createActionCreator } from 'deox';
import { HeurekaPatients } from '../../types/heurekaPatient';
import { Medication } from '../../types/medication';
import { LinkHeurekaToPatientDTO } from '../../types/dto/patient';

const createRequestActions = createRequestActionsCreator('heureka');

export const setHeurekaPatients = createActionCreator(
  'heureka/SET_HEUREKA_PATIENTS',
  (resolve) => (heurekaPatients: HeurekaPatients) => resolve({ heurekaPatients }),
);

export const setMedications = createActionCreator(
  'heureka/SET_MEDICATIONS',
  (resolve) => (medications: Medication[]) => resolve({ medications }),
);

export const heurekaRequestActionBundles = {
  fetchHeurekaPatients: createRequestActions(
    'FETCH_HEUREKA_PATIENTS',
    (resolve) => (searchString: string) => resolve({ searchString }),
  ),
  fetchMedications: createRequestActions('FETCH_MEDICATIONS'),
  linkHeureka: createRequestActions(
    'LINK_HEUREKA',
    (resolve) => (linkHeurekaToPatientDTO: LinkHeurekaToPatientDTO) => resolve(linkHeurekaToPatientDTO),
  ),
  connectHeureka: createRequestActions(
    'CONNECT_HEUREKA',
    (resolve) => (code?: string, nonce?: string) => resolve({ code, nonce }),
  ),
};
