import { call, debounce, put, select } from 'typed-redux-saga/macro';
import { endpoints } from '../api';
import { selectOrganizationId } from '../data/selector';
import { ActionType } from 'deox';
import { heurekaRequestActionBundles, setHeurekaPatients } from './actions';

function* requestSaga({ payload }: ActionType<typeof heurekaRequestActionBundles.fetchHeurekaPatients.sendRequest>) {
  try {
    const organizationId = yield* select(selectOrganizationId);
    const { data } = yield* call(endpoints.heurekaPatient.fetchAll, organizationId, payload.searchString);

    yield* put(setHeurekaPatients(data.items));
    yield* put(heurekaRequestActionBundles.fetchHeurekaPatients.requestSucceeded());
  } catch (error) {
    console.error('fetch patients request failed', error);
    yield* put(heurekaRequestActionBundles.fetchHeurekaPatients.requestFailed());
  }
}

export function* heurekaPatientsFetchSaga() {
  yield* debounce(300, heurekaRequestActionBundles.fetchHeurekaPatients.sendRequest, requestSaga);
}
