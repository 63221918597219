import { RootState } from '../../store';
import { connect } from 'react-redux';
import { selectOrganization, selectPatientDetails } from '../../store/data/selector';
import Sidebar from './Sidebar';

const mapStateToProps = (state: RootState) => ({
  patientDetails: selectPatientDetails(state),
  organization: selectOrganization(state),
});

type StateProps = ReturnType<typeof mapStateToProps>;
export type Props = Readonly<StateProps>;

export default connect(mapStateToProps)(Sidebar);
