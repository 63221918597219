import { call, put, select, takeLatest } from 'typed-redux-saga/macro';
import { endpoints } from '../api';
import { selectOrganizationId } from '../data/selector';
import { selectCurrentPatientIdOrThrow } from '../patient-list/selector';
import { heurekaRequestActionBundles, setMedications } from './actions';
import { createMedications } from './convertUtils';

function* requestSaga() {
  try {
    const organizationId = yield* select(selectOrganizationId);
    const patientId = yield* select(selectCurrentPatientIdOrThrow);
    const { data } = yield* call(endpoints.medication.fetchAll, organizationId, patientId);

    yield* put(setMedications(createMedications(data.items)));
    yield* put(heurekaRequestActionBundles.fetchMedications.requestSucceeded());
  } catch (error) {
    console.error('fetch patients request failed', error);
    yield* put(heurekaRequestActionBundles.fetchMedications.requestFailed());
  }
}

export function* medicationsFetchSaga() {
  yield* takeLatest(heurekaRequestActionBundles.fetchMedications.sendRequest, requestSaga);
  yield* takeLatest(heurekaRequestActionBundles.linkHeureka.requestSucceeded, requestSaga);
}
