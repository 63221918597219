import { Message } from '../../types/message';
import { RetrieveMessageDTO, RetrieveMessageType } from '../../types/dto/message';

export const convertMessage = (retrieveMessageDTO: RetrieveMessageDTO, patientId: string): Message => {
  const { id, type, text, authoredDate: stringAuthoredDate, senderId, receiverId, ...rest } = retrieveMessageDTO;
  const sentByPatient = senderId === patientId;
  const authoredDate = new Date(stringAuthoredDate).getTime();
  if (type === RetrieveMessageType.FILE && 'fileMimeType' in rest) {
    return { id, type, text, authoredDate, sentByPatient, ...rest };
  } else if (type === RetrieveMessageType.TEXT && text) {
    const quotedMessage = retrieveMessageDTO.quotedMessage;
    return {
      id,
      type,
      text,
      authoredDate,
      sentByPatient,
      quotedMessage: quotedMessage ? convertMessage(quotedMessage, patientId) : quotedMessage,
    };
  } else {
    throw Error('Message conversion failed');
  }
};

export const convertFile = (file: File, text: string, index: number, correlationId: string): FormData => {
  const formData = new FormData();
  formData.append('correlationId', correlationId);
  if (text && index === 0) {
    formData.append('description', text);
  }
  formData.append('fileName', file.name);
  formData.append('fileSize', file.size.toString());
  formData.append('fileMimeType', file.type);
  formData.append('file', file);
  return formData;
};
