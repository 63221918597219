import { useCallback, useState } from 'react';

export function useFileUpload() {
  const [files, setFiles] = useState<File[]>([]);

  return {
    files,
    addFiles: useCallback((newFiles: File[]) => setFiles((files) => files.concat(newFiles)), []),
    removeFile: useCallback((index: number) => setFiles((files) => files.filter((_, i) => index !== i)), []),
    clearFiles: useCallback(() => setFiles([]), []),
  };
}
