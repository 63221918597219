import { RootState } from '../index';

export const selectTextForChat = ({ chats, patientList: { currentPatientId } }: RootState) =>
  currentPatientId ? chats.texts[currentPatientId] ?? '' : '';

export const selectMessagesForChat = ({ chats, patientList: { currentPatientId } }: RootState) =>
  currentPatientId ? chats.messages[currentPatientId] ?? [] : [];

export const selectThumbnailUrl =
  (messageId: string) =>
  ({ chats }: RootState) =>
    chats.thumbnailUrls[messageId];

export const selectThumbnailUrls =
  (patientId: string) =>
  ({ chats }: RootState) =>
    chats.messages[patientId]?.map((message) => chats.thumbnailUrls[message.id]) ?? [];
