import { RootState } from '../../store';
import { Action, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { selectOrganization, selectPatientDetails } from '../../store/data/selector';
import { pipe } from 'ramda';
import HeurekaConnector from './HeurekaConnector';
import { selectHeurekaPatient } from '../../store/heureka/selector';
import { heurekaRequestActionBundles } from '../../store/heureka/actions';

const mapStateToProps = (state: RootState) => ({
  organization: selectOrganization(state),
  heurekaPatients: selectHeurekaPatient(state),
  patientDetails: selectPatientDetails(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  searchHeurekaPatients: pipe(heurekaRequestActionBundles.fetchHeurekaPatients.sendRequest, dispatch),
  linkHeurekaIdToPatient: pipe(heurekaRequestActionBundles.linkHeureka.sendRequest, dispatch),
  connectHeureka: pipe(heurekaRequestActionBundles.connectHeureka.sendRequest, dispatch),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
export type Props = Readonly<StateProps & DispatchProps>;

export default connect(mapStateToProps, mapDispatchToProps)(HeurekaConnector);
