export enum MimeType {
  IMAGE_JPEG = 'image/jpeg',
  IMAGE_PNG = 'image/png',
  IMAGE_GIF = 'image/gif',
  IMAGE_HEIF = 'image/heif',
  IMAGE_HEIC = 'image/heic',
  IMAGE_TIFF = 'image/tiff',
  VIDEO_MPEG = 'video/mpeg',
  VIDEO_MP4 = 'video/mp4',
  VIDEO_AVC = 'video/avc',
  AUDIO_AAC = 'audio/aac',
  AUDIO_MIDI = 'audio/midi',
  AUDIO_XMIDI = 'audio/x-midi',
  AUDIO_FLAC = 'audio/flac',
  AUDIO_XFLAC = 'audio/x-flac',
  APPLICATION_ZIP = 'application/zip',
  APPLICATION_PDF = 'application/pdf',
  TEXT_X_VCARD = 'text/x-vcard',
  TEXT_VCARD = 'text/vcard',
  TEXT_PLAIN = 'text/plain',
  TEXT_HTML = 'text/html',
  APPLICATION_OCTETSTREAM = 'application/octet-stream',
}
