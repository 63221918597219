import { endpoints } from '../api';
import { ActionType } from 'deox';
import { all, put, select, takeLatest } from 'typed-redux-saga/macro';
import { requestActionBundles } from './action';
import { selectCurrentPatientIdOrThrow } from '../patient-list/selector';
import { selectOrganizationId } from './selector';
import { selectTextForChat } from '../chats/selector';
import { setTextForChat } from '../chats/action';
import { v4 as uuidv4 } from 'uuid';
import { convertFile } from '../chats/convertUtils';

function* requestSaga({ payload: { files } }: ActionType<typeof requestActionBundles.sendMessage.sendRequest>) {
  try {
    const organizationId = yield* select(selectOrganizationId);
    const patientId = yield* select(selectCurrentPatientIdOrThrow);
    const text = yield* select(selectTextForChat);

    yield* put(setTextForChat(patientId, ''));

    if (files.length) {
      const correlationId = uuidv4().replaceAll('-', '');
      yield* all(
        files.map(async (file, index) => {
          const formData = convertFile(file, text, index, correlationId);
          const { data } = await endpoints.message.sendFile(organizationId, patientId, formData);
          return data;
        }),
      );
    } else {
      yield endpoints.message.sendText(organizationId, patientId, text);
    }

    yield* put(requestActionBundles.sendMessage.requestSucceeded());
    yield* put(requestActionBundles.fetchMessages.sendRequest()); // TODO: more efficient
  } catch (error) {
    console.error('sendText message request failed', error);
    yield* put(requestActionBundles.sendMessage.requestFailed());
  }
}

export function* messageSendSaga() {
  yield* takeLatest(requestActionBundles.sendMessage.sendRequest, requestSaga);
}
