import { put, select, takeEvery, takeLatest } from 'typed-redux-saga/macro';
import { clearThumbnailUrls } from './action';
import { selectCurrentPatientId, selectPreviousPatientId } from '../patient-list/selector';
import { setCurrentPatientId } from '../patient-list/action';
import { eventChannel } from 'redux-saga';
import { selectThumbnailUrls } from './selector';

const terminationEvent = 'onpagehide' in window.self ? 'pagehide' : 'unload';

const channel = eventChannel((listener) => {
  const callback = () => {
    listener('CLEAR');
  };

  window.addEventListener(terminationEvent, callback, { capture: true });
  return () => window.removeEventListener(terminationEvent, callback);
});

function* revokeThumbnailsSaga(patientId: string) {
  const thumbnailUrls: string[] = yield* select(selectThumbnailUrls(patientId));
  thumbnailUrls.map((thumbnailUrl) => URL.revokeObjectURL(thumbnailUrl));
  yield* put(clearThumbnailUrls(patientId));
}

function* revokePreviousPatientThumbnailsSaga() {
  const patientId = yield* select(selectPreviousPatientId);
  if (patientId) yield revokeThumbnailsSaga(patientId);
}

function* revokeCurrentPatientThumbnailsSaga() {
  const patientId = yield* select(selectCurrentPatientId);
  if (patientId) yield revokeThumbnailsSaga(patientId);
}

export function* thumbnailReleaseSaga() {
  yield* takeLatest(setCurrentPatientId, revokePreviousPatientThumbnailsSaga);
  yield* takeEvery(channel, revokeCurrentPatientThumbnailsSaga);
}
