import { InformationRow } from '../InformationRow';
import { Props } from './HeurekaConnectorContainer';
import LinkPatient from './LinkPatient';
import HeurekaButton from './HeurekaButton';

export default function HeurekaConnectorContent({
  organization,
  heurekaPatients,
  searchHeurekaPatients,
  linkHeurekaIdToPatient,
  connectHeureka,
  patientDetails,
}: Props) {
  if (organization?.heurekaConnected && !patientDetails) {
    return (
      <InformationRow>
        <div>Status</div>
        <div>Verbunden</div>
      </InformationRow>
    );
  }
  if (!organization?.heurekaConnected) {
    return <HeurekaButton connectHeureka={connectHeureka} />;
  }

  return (
    <LinkPatient
      heurekaPatients={heurekaPatients}
      searchHeurekaPatients={searchHeurekaPatients}
      linkHeurekaIdToPatient={linkHeurekaIdToPatient}
      patientDetails={patientDetails}
    />
  );
}
