import reactStringReplace from 'react-string-replace';

function createUniqueKey(match: string, index: number) {
  return match + index.toString() + Math.random().toString();
}

export function createMarkupText(text: string) {
  let markupText = reactStringReplace(text, /\*(.*?)\*/g, (match, i) => <b key={createUniqueKey(match, i)}>{match}</b>);
  markupText = reactStringReplace(markupText, /_(.*?)_/g, (match, i) => <i key={createUniqueKey(match, i)}>{match}</i>);
  return reactStringReplace(markupText, /~(.*?)~/g, (match, i) => <s key={createUniqueKey(match, i)}>{match}</s>);
}
