import { DesktopDatePicker } from '@mui/x-date-pickers';
import { Box, Button, Stack, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { formatISO } from 'date-fns';
import { CompletePatient } from '../../types/patients';
import { PatientMutationDTO } from '../../types/dto/patient';
import { CancelOutlined, CheckCircleOutlined } from '@mui/icons-material';

interface EditPatientDetailsForm {
  pid: string;
  firstName: string;
  lastName: string;
  dateOfBirth: Date | null;
  threemaId: string;
}

export default function EditPatientDetails({
  patientDetails,
  patchPatientDetails,
  stopEditing,
}: {
  patientDetails: CompletePatient;
  patchPatientDetails: (patientMutation: PatientMutationDTO) => void;
  stopEditing: () => void;
}) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<EditPatientDetailsForm>({
    defaultValues: {
      pid: patientDetails.pid,
      firstName: patientDetails.firstName,
      lastName: patientDetails.lastName,
      dateOfBirth: patientDetails.dateOfBirth ? new Date(patientDetails.dateOfBirth) : null,
    },
  });

  const onSubmit = handleSubmit(({ pid, firstName, lastName, dateOfBirth }) => {
    patchPatientDetails({
      pid,
      firstName,
      lastName,
      dateOfBirth: dateOfBirth ? formatISO(dateOfBirth, { representation: 'date' }) : '',
      threemaId: patientDetails.threemaId,
    });
    stopEditing();
  });

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={2} mt={2}>
        <TextField size="small" {...register('pid', { required: true })} label="PID" error={!!errors.pid} />
        <TextField
          size="small"
          {...register('firstName', { required: true })}
          label="Vorname"
          error={!!errors.firstName}
        />
        <TextField
          size="small"
          {...register('lastName', { required: true })}
          label="Nachname"
          error={!!errors.lastName}
        />
        <Controller
          name="dateOfBirth"
          control={control}
          render={({ field }) => (
            <DesktopDatePicker
              {...field}
              label="Geburtsdatum"
              slotProps={{ textField: { size: 'small', error: !!errors.dateOfBirth } }}
            />
          )}
        />
        <Box gap={1} display="flex" justifyContent="end" flexWrap="wrap">
          {patientDetails.isConfirmed && (
            <Button variant="outlined" onClick={stopEditing} startIcon={<CancelOutlined />}>
              Abbrechen
            </Button>
          )}
          <Button variant="contained" type="submit" startIcon={<CheckCircleOutlined />}>
            Speichern
          </Button>
        </Box>
      </Stack>
    </form>
  );
}
