import { Avatar, useTheme } from '@mui/material';
import { CompactPatient } from '../../types/patients';
import { abbreviatePatientName } from '../../utils/formatters';

export default function PatientAvatar({ patient }: { patient: CompactPatient }) {
  const theme = useTheme();
  const abbreviation = abbreviatePatientName(patient);

  if (abbreviation) {
    return (
      <Avatar
        sx={{
          marginRight: 1,
          backgroundColor: theme.palette.grey[100],
          color: theme.palette.green[700],
          fontSize: 17,
          fontWeight: 600,
        }}>
        {abbreviation}
      </Avatar>
    );
  }

  return <Avatar sx={{ marginRight: 1, backgroundColor: theme.palette.info.main }} />;
}
