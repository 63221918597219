import { createReducer } from 'deox';
import { ImageInfo } from '../../utils/getImageInfo';
import { close, setImage, setIsLoading, setItem, setLoaded, setRotation } from './action';
import { MimeType } from '../../types/mimeType';
import { setCurrentPatientId } from '../patient-list/action';

export interface ViewerItem {
  id: string;
  mimeType: MimeType;
  fileName: string;
}

const initialState: Readonly<{
  item?: ViewerItem;
  isLoading: boolean;
  loaded: number;
  image?: ImageInfo;
  rotation: number;
}> = {
  isLoading: false,
  loaded: 0,
  rotation: 0,
};

export const fileViewerReducer = createReducer(initialState, (handleAction) => [
  handleAction(setItem, (state, { payload }) => ({
    ...state,
    item: payload,
    rotation: 0,
  })),
  handleAction([close, setCurrentPatientId], (state) => ({
    ...state,
    item: undefined,
  })),
  handleAction(setIsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),
  handleAction(setLoaded, (state, { payload }) => ({
    ...state,
    loaded: payload,
  })),
  handleAction(setImage, (state, { payload }) => ({
    ...state,
    image: payload,
  })),
  handleAction(setRotation, (state, { payload }) => ({
    ...state,
    rotation: payload,
  })),
]);
