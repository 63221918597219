export interface ImageInfo {
  url: string;
  height: number;
  width: number;
}

export const getImageInfo = async (url: string) =>
  await new Promise<ImageInfo>((resolve, reject) => {
    const image = new Image();
    image.src = url;
    image.onload = () => resolve({ url, height: image.height, width: image.width });
    image.onerror = reject;
  });
