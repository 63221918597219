import { map, values } from 'ramda';
import { createRequestActionsCreator } from '@healthinal/typescript-base-lib';
import { createActionCreator } from 'deox';
import { CompactPatients, CompletePatient } from '../../types/patients';
import { PatientMutationDTO } from '../../types/dto/patient';
import { Organization } from '../../types/organization';

const createRequestActions = createRequestActionsCreator('data');

export const setOrganizationId = createActionCreator(
  'common/data/SET_ORGANIZATION_ID',
  (resolve) => (organizationId: string) => resolve({ organizationId }),
);

export const setPatients = createActionCreator(
  'data/SET_PATIENTS',
  (resolve) => (patients: CompactPatients) => resolve({ patients }),
);

export const setPatientDetails = createActionCreator(
  'data/SET_PATIENT_DETAILS',
  (resolve) => (patientDetails: CompletePatient) => resolve({ patientDetails }),
);

export const setOrganization = createActionCreator(
  'data/SET_ORGANIZATION',
  (resolve) => (organization: Organization) => resolve({ organization }),
);

export const startListeningForChatNotifications = createActionCreator('data/LISTEN_FOR_NOTIFICATIONS');

export const requestActionBundles = {
  fetchPatients: createRequestActions('FETCH_PATIENTS'),
  fetchOrganization: createRequestActions('FETCH_ORGANIZATION'),
  fetchPatientDetails: createRequestActions('FETCH_PATIENT_DETAILS'),
  patchPatientDetails: createRequestActions(
    'PATCH_PATIENT_DETAILS',
    (resolve) => (patientMutation: PatientMutationDTO) => resolve(patientMutation),
  ),
  fetchMessages: createRequestActions('FETCH_MESSAGES'),
  fetchMessageFile: createRequestActions('FETCH_MESSAGE_FILE', (resolve) => (fileId: string) => resolve({ fileId })),
  sendMessage: createRequestActions('SEND_MESSAGE', (resolve) => (files: File[]) => resolve({ files })),
};

const bundlesAsArray = values(requestActionBundles);
export const sendRequestActions = map(({ sendRequest }) => sendRequest, bundlesAsArray);
export const requestSucceededActions = map(({ requestSucceeded }) => requestSucceeded, bundlesAsArray);
export const requestFailedActions = map(({ requestFailed }) => requestFailed, bundlesAsArray);
