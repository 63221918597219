import { put, takeLatest } from 'typed-redux-saga/macro';
import { requestActionBundles } from '../data/action';
import { setCurrentPatientId } from './action';

function* dispatchSaga() {
  yield* put(requestActionBundles.fetchPatientDetails.sendRequest());
  yield* put(requestActionBundles.fetchMessages.sendRequest());
}

export function* selectPatientSaga() {
  yield* takeLatest(setCurrentPatientId, dispatchSaga);
}
