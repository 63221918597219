import Keycloak from 'keycloak-js';

export const keycloak: Keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: 'app',
  clientId: 'webapp',
});

export const getToken = (): Promise<string> =>
  new Promise((resolve, reject) => {
    keycloak
      // refresh token if the validity is less than 20 seconds
      .updateToken(20)
      .then(() => {
        if (keycloak.token) {
          resolve(keycloak.token);
        } else {
          throw Error('No keycloak token');
        }
      })
      .catch(reject);
  });

export const parseKeycloakOrganizationId = (): string => {
  const tokenParsed = keycloak.tokenParsed;
  if (tokenParsed && typeof tokenParsed.organization_id === 'string') {
    return tokenParsed.organization_id;
  }
  throw Error('Could not parse keycloak token');
};
