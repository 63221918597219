import { Medication } from '../../types/medication';

function createMedication(m: Medication) {
  return {
    medicationName: m?.medicationName,
    dosingSchedule: m?.dosingSchedule,
    commentOnDosingSchedule: m?.commentOnDosingSchedule,
    comment: m?.comment,
    from: m.from ? new Date(m.from) : undefined,
    until: m.until ? new Date(m.until) : undefined,
  };
}

export function createMedications(medications: Medication[]) {
  return medications.map((m) => createMedication(m));
}
