import { endpoints } from '../api';
import { call, put, select, takeLatest } from 'typed-redux-saga/macro';
import { requestActionBundles, setOrganization } from './action';
import { selectOrganizationId } from './selector';

function* requestSaga() {
  try {
    const organizationId = yield* select(selectOrganizationId);
    const { data } = yield* call(endpoints.organization.fetchOne, organizationId);

    yield* put(setOrganization(data));
    yield* put(requestActionBundles.fetchOrganization.requestSucceeded());
  } catch (error) {
    console.error('fetch patient details request failed', error);
    yield* put(requestActionBundles.fetchOrganization.requestFailed());
  }
}

export function* organizationFetchSaga() {
  yield* takeLatest(requestActionBundles.fetchOrganization.sendRequest, requestSaga);
}
