import { Avatar, Box, Chip, Tooltip } from '@mui/material';
import { Props } from './HeurekaConnectorContainer';
import HeurekaConnectorContent from './HeurekaConnectorContent';
import MedicationsContainer from '../Medication/MedicationsContainer';
import SidebarComponentTitle from '../Sidebar/SiedebarComponentTitle';

export default function HeurekaConnector({
  organization,
  heurekaPatients,
  searchHeurekaPatients,
  linkHeurekaIdToPatient,
  connectHeureka,
  patientDetails,
}: Props) {
  if (patientDetails?.heurekaId) {
    return (
      <>
        <Box display="flex" justifyContent="end" pt={2} pr={3}>
          <Tooltip title="Patient ist mit Heureka Health gekoppelt" placement="top-start">
            <Chip
              variant="outlined"
              size={'medium'}
              label="Heureka Health"
              avatar={<Avatar src="/icons/logomark.png"></Avatar>}
              onDelete={() => {
                if (window.confirm('Wollen Sie den Patienten wirklich von Heureka Health entkoppeln?'))
                  linkHeurekaIdToPatient({});
              }}></Chip>
          </Tooltip>
        </Box>
        <MedicationsContainer />
      </>
    );
  }

  return (
    <Box p={3}>
      <SidebarComponentTitle>Heureka Health</SidebarComponentTitle>
      <HeurekaConnectorContent
        organization={organization}
        heurekaPatients={heurekaPatients}
        searchHeurekaPatients={searchHeurekaPatients}
        linkHeurekaIdToPatient={linkHeurekaIdToPatient}
        connectHeureka={connectHeureka}
        patientDetails={patientDetails}
      />
    </Box>
  );
}
