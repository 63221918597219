import { RootState } from '../index';
import { any, eqBy, isEmpty, pipe } from 'ramda';
import { removeRequestSuffixFromActionType } from '@healthinal/typescript-base-lib';
import { requestActionBundles } from './action';
import { selectCurrentPatientId, selectPatientSearchText } from '../patient-list/selector';
import { createSelector } from 'reselect';

const selectDataState = ({ data }: RootState) => data;
export const selectOrganizationId = pipe(selectDataState, ({ organizationId }) => organizationId);

export const selectPatients = pipe(selectDataState, ({ patients }) => patients);

export const selectNumberOfUnreadPatients = createSelector(
  selectPatients,
  selectCurrentPatientId,
  (patients, currentPatientId) =>
    patients.filter(({ id, unreadMessagesCount }) => id !== currentPatientId && unreadMessagesCount).length,
);

export const selectSearchedPatients = createSelector(selectPatients, selectPatientSearchText, (patients, searchText) =>
  !isEmpty(searchText)
    ? patients.filter(
        (patient) =>
          `${patient.firstName} ${patient.lastName}`.toLowerCase().includes(searchText.toLowerCase().trim()) ||
          patient.pid?.toLowerCase().includes(searchText.toLowerCase().trim()) ||
          patient.dateOfBirth?.includes(searchText.toLowerCase().trim()),
      )
    : patients,
);

export const selectPatientDetails = pipe(selectDataState, ({ patientDetails }) => patientDetails);

export const selectOrganization = pipe(selectDataState, ({ organization }) => organization);

export const selectIsRequestRunning = pipe(selectDataState, ({ requestsRunning }) => requestsRunning.length > 0);

const makeGetIsRequestRunning = (type: string) =>
  pipe(selectDataState, ({ requestsRunning }) => any(eqBy(removeRequestSuffixFromActionType, type), requestsRunning));

export const selectIsLoadingPatients = makeGetIsRequestRunning(requestActionBundles.fetchPatients.sendRequest().type);

export const selectIsLoadingPatientDetails = makeGetIsRequestRunning(
  requestActionBundles.fetchPatientDetails.sendRequest().type,
);

export const selectIsLoadingMessages = makeGetIsRequestRunning(requestActionBundles.fetchMessages.sendRequest().type);
