import { MimeType } from '../mimeType';

type RetrieveBaseMessageDTO = {
  id: string;
  authoredDate: string;
  senderId: string;
  receiverId: string;
};

export enum RetrieveMessageType {
  TEXT = 'TEXT',
  FILE = 'FILE',
}

export type RetrieveTextMessageDTO = RetrieveBaseMessageDTO & {
  type: RetrieveMessageType.TEXT;
  text: string;
  quotedMessage?: RetrieveMessageDTO;
};

export type RetrieveFileMessageDTO = RetrieveBaseMessageDTO & {
  type: RetrieveMessageType.FILE;
  text?: string;
  fileMimeType: MimeType;
  fileName?: string;
  thumbnailHeight?: number;
  thumbnailWidth?: number;
};

export type RetrieveMessageDTO = RetrieveTextMessageDTO | RetrieveFileMessageDTO;

export type RetrieveMessagesDTO = RetrieveMessageDTO[];

export type MessageNotificationDTO = {
  patientId: string;
};
