import { CloseTwoTone, Download, Rotate90DegreesCcwTwoTone, Rotate90DegreesCwTwoTone } from '@mui/icons-material';
import { Card, CircularProgress, Divider, IconButton, styled } from '@mui/material';
import { Props } from './FileViewerContainer';
import useResizeObserver from 'use-resize-observer';
import theme from '../../theme';
import { downloadURL } from '../../utils/downloadURL';

const ViewerRoot = styled(Card)`
  position: relative;
  grid-column: 1;
  grid-row: 1;
  display: grid;
  grid-template-rows: auto auto 1fr;
  background-color: ${theme.palette.grey[200]};
`;

const HeaderButtons = styled('div')`
  display: flex;
  justify-content: end;
  background-color: white;
`;

const HeaderButton = styled(IconButton)`
  margin: 8px;
`;

const ProgressContainer = styled('div')`
  display: grid;
  & > * {
    grid-row: 1;
    grid-column: 1;
    justify-self: center;
    align-self: center;
  }
`;

const Progress = styled(CircularProgress)`
  .MuiCircularProgress-circle {
    transition-duration: 0.03s; // otherwise it isn't fast enough
  }
`;

const ImageContainer = styled('div')`
  display: grid;
  min-height: 0;
`;

const Image = styled('img')`
  min-height: 0;
  max-height: 100%;
  max-width: 100%;
  justify-self: center;
  align-self: center;
`;

export default function FileViewer({ item, isLoading, loaded, image, close, rotation, setRotation }: Props) {
  const rotateBack = () => setRotation((rotation + 270) % 360);
  const rotate = () => setRotation((rotation + 90) % 360);
  const { ref: containerRef, height: containerHeight, width: containerWidth } = useResizeObserver<HTMLDivElement>();
  const { ref: imageRef, height: imageHeight, width: imageWidth } = useResizeObserver<HTMLDivElement>();

  if (!item) {
    return null;
  }

  if (isLoading) {
    return (
      <ViewerRoot>
        <HeaderButtons>
          <HeaderButton onClick={() => close()}>
            <CloseTwoTone />
          </HeaderButton>
        </HeaderButtons>
        <Divider />
        <ProgressContainer>
          <Progress size={50} variant="determinate" value={loaded * 100} key={item?.id} />
          <span>{Math.round(loaded * 100)}%</span>
        </ProgressContainer>
      </ViewerRoot>
    );
  }

  const transformations = [`rotate(${rotation}deg)`];
  if (rotation % 180 && image?.height && containerHeight && containerWidth && imageHeight && imageWidth) {
    // maximum scale that image size will allow
    const maxScale = image.height / imageHeight;
    const scale = Math.min(containerWidth / imageHeight, containerHeight / imageWidth, maxScale);
    transformations.push(`scale(${scale})`);
  }

  const download = () => {
    if (!image) {
      return;
    }
    downloadURL(image.url, item.fileName);
  };

  return (
    <ViewerRoot>
      <HeaderButtons>
        <HeaderButton onClick={rotateBack}>
          <Rotate90DegreesCcwTwoTone />
        </HeaderButton>
        <HeaderButton onClick={rotate}>
          <Rotate90DegreesCwTwoTone />
        </HeaderButton>
        <HeaderButton onClick={download}>
          <Download />
        </HeaderButton>
        <HeaderButton onClick={close}>
          <CloseTwoTone />
        </HeaderButton>
      </HeaderButtons>
      <Divider />
      <ImageContainer ref={containerRef}>
        <Image src={image?.url} sx={{ transform: transformations.join(' ') }} ref={imageRef} />
      </ImageContainer>
    </ViewerRoot>
  );
}
