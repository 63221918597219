import React from 'react';
import ReactDOM from 'react-dom';
import { keycloak, parseKeycloakOrganizationId } from './keycloak';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Offline } from '@sentry/integrations';
import App from './components/App';
import setupStore from './store';
import { Provider } from 'react-redux';
import { setOrganizationId } from './store/data/action';
import { environment } from './utils/environment';
import { instrumentAxios, sentrySessionId, sentryUserId } from '@healthinal/typescript-base-lib';

keycloak
  .init({ onLoad: 'login-required' })
  .then((authenticated) => {
    if (!authenticated) throw Error('authentication failed');

    Sentry.init({
      dsn: environment === 'localhost' ? '' : process.env.REACT_APP_SENTRY_DSN,
      integrations: [new BrowserTracing(), new Offline()],
      tracesSampleRate: 0.1,
      autoSessionTracking: false,
      environment,
      release: process.env.REACT_APP_FRONTEND_VERSION,
      normalizeDepth: 10,
    });

    instrumentAxios();

    Sentry.configureScope((scope) => {
      scope.setTag('session_id', sentrySessionId);
      scope.setTag('user_id', sentryUserId);
    });

    //TODO: setup translations
    const store = setupStore();
    store.dispatch(setOrganizationId(parseKeycloakOrganizationId()));

    ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          <App />
        </Provider>
      </React.StrictMode>,
      document.getElementById('root'),
    );
  })
  .catch((e: Error) => {
    console.error(e);
  });
