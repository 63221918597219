import { connect } from 'react-redux';
import PatientList from './PatientList';
import { Action, Dispatch } from 'redux';
import { RootState } from '../../store';
import {
  selectSearchedPatients,
  selectIsLoadingPatients,
  selectNumberOfUnreadPatients,
} from '../../store/data/selector';
import { selectCurrentPatientId, selectPatientSearchText } from '../../store/patient-list/selector';
import { pipe } from 'ramda';
import { setCurrentPatientId, setPatientSearchText } from '../../store/patient-list/action';

const mapStateToProps = (state: RootState) => ({
  patients: selectSearchedPatients(state),
  patientsLoading: selectIsLoadingPatients(state),
  currentPatientId: selectCurrentPatientId(state),
  numberOfUnreadPatients: selectNumberOfUnreadPatients(state),
  patientSearchText: selectPatientSearchText(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setSelectedPatient: pipe(setCurrentPatientId, dispatch),
  setPatientSearchText: pipe(setPatientSearchText, dispatch),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
export type Props = Readonly<StateProps & DispatchProps>;

export default connect(mapStateToProps, mapDispatchToProps)(PatientList);
