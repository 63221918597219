import { useEffect, useState, useCallback } from 'react';
import { Box, CircularProgress, Divider, InputAdornment, ListItemButton, styled, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import { Props } from './PatientListContainer';
import PatientAvatar from './PatientAvatar';
import theme from '../../theme';
import { Virtuoso } from 'react-virtuoso';
import { motion } from 'framer-motion';
import { CompactPatient } from '../../types/patients';
import logo from './Logo.svg';

const DOCUMENT_TITLE = 'care chat';

const PatientListRoot = styled(Box)`
  flex: 1;
  display: grid;
  grid-template-rows: auto auto auto 1fr;
`;

const Logo = styled('img')`
  max-width: 100%;
  min-width: 0;
  max-height: 34px;
`;

const Patient = styled(ListItemButton)`
  padding: ${theme.spacing(1.5, 3)};
  &::after {
    transition: background-color 0.15s;
    content: '';
    background-color: rgba(255, 255, 255, 0);
    height: 1px;
    position: absolute;
    left: ${theme.spacing(3)};
    right: ${theme.spacing(3)};
    bottom: -1px;
  }
  &:not(:hover):not(.Mui-selected)::after {
    background-color: ${theme.palette.green[100]};
  }
  &:hover {
    background: ${theme.palette.green[50]};
  }
  &.Mui-selected {
    background: ${theme.palette.green[200]};
    box-shadow: inset -4px 0px 0px rgb(0, 161, 95);
    &:hover {
      background: ${theme.palette.green[300]};
    }
  }
`;

const UnreadMarker = styled(Box)`
  color: ${theme.palette.primary.contrastText};
  background-color: ${theme.palette.primary.main};
  display: inline-block;
  border-radius: 20px;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  padding: 0 5px;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  margin-left: auto;
`;

const Loading = styled(CircularProgress)`
  justify-self: center;
  margin-top: 16px;
`;

export default function PatientList({
  patients,
  patientsLoading,
  currentPatientId,
  numberOfUnreadPatients,
  patientSearchText,
  setSelectedPatient,
  setPatientSearchText,
}: Props) {
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    document.title = numberOfUnreadPatients ? `(${numberOfUnreadPatients}) ${DOCUMENT_TITLE}` : DOCUMENT_TITLE;
  }, [numberOfUnreadPatients]);

  // change all keys when isScrolling changes, otherwise motion items with layout=false (no animation) will remember that choice and not animate.
  const computeItemKey = useCallback(
    (_index: number, patient: CompactPatient) => `${patient.id}_isScrolling:${isScrolling}`,
    [isScrolling],
  );

  return (
    <PatientListRoot>
      <Box px={3} pt={3} pb={1} display="flex" gap={1} alignItems="center">
        <Logo src={logo} />
        {numberOfUnreadPatients > 0 && <UnreadMarker>{numberOfUnreadPatients}</UnreadMarker>}
      </Box>
      <Box px={3} py={2}>
        <TextField
          size="small"
          sx={{
            display: 'flex',
            '.MuiOutlinedInput-root': {
              background: theme.palette.grey[50],
              borderRadius: 1,
              '&.Mui-focused': {
                background: theme.palette.grey[100],
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          placeholder="Patient suchen"
          onChange={(event) => setPatientSearchText(event.target.value)}
          value={patientSearchText}
        />
      </Box>
      <Divider />
      {patientsLoading && !patients.length ? (
        <Loading />
      ) : (
        <Virtuoso
          data={patients}
          computeItemKey={computeItemKey}
          itemContent={(_index, patient) => {
            const selected = currentPatientId === patient.id;
            const name = patient.firstName ? `${patient.firstName} ${patient.lastName}` : 'Neuer Patient';
            return (
              <motion.div layout={!isScrolling}>
                <Patient
                  onClick={() => {
                    if (!selected) setSelectedPatient(patient.id);
                  }}
                  key={patient.id}
                  selected={selected}>
                  <PatientAvatar patient={patient} />
                  {name}
                  {patient.unreadMessagesCount > 0 && !selected && (
                    <UnreadMarker>{patient.unreadMessagesCount}</UnreadMarker>
                  )}
                </Patient>
              </motion.div>
            );
          }}
          isScrolling={setIsScrolling}
        />
      )}
    </PatientListRoot>
  );
}
