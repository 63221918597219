import React, { useEffect } from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import { Props } from './QuotedFileMessageImageContainer';

export function QuotedFileMessageImage({
  thumbnailUrl,
  loadThumbnail,
  patientId,
  quotedMessage,
  organizationId,
}: Props) {
  useEffect(() => {
    if (!thumbnailUrl && patientId) {
      loadThumbnail(organizationId, patientId, quotedMessage.id);
    }
  }, [quotedMessage.id, loadThumbnail, organizationId, patientId, thumbnailUrl]);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', pr: 1, alignSelf: 'flex-end' }}>
        <Typography sx={{ fontSize: 'small' }}>Bild</Typography>
      </Box>
      {thumbnailUrl ? (
        <Box sx={{ backgroundImage: `url(${thumbnailUrl})`, backgroundSize: '50px' }} width={50} height={50} />
      ) : (
        <Skeleton variant="rectangular" width={50} height={50} />
      )}
    </>
  );
}
