import { RootState } from '../../store';
import { connect } from 'react-redux';
import {
  selectItem,
  selectIsLoading,
  selectLoaded,
  selectImage,
  selectRotation,
} from '../../store/file-viewer/selector';
import FileViewer from './FileViewer';
import { close, setRotation } from '../../store/file-viewer/action';
import { pipe } from 'ramda';
import { Action, Dispatch } from 'redux';

const mapStateToProps = (state: RootState) => ({
  item: selectItem(state),
  isLoading: selectIsLoading(state),
  loaded: selectLoaded(state),
  image: selectImage(state),
  rotation: selectRotation(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  close: pipe(close, dispatch),
  setRotation: pipe(setRotation, dispatch),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
export type Props = Readonly<StateProps & DispatchProps>;

export default connect(mapStateToProps, mapDispatchToProps)(FileViewer);
