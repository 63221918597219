import { Box } from '@mui/material';
import { Message } from '../../../../types/message';
import theme from '../../../../theme';
import QuotedFileMessage from './QuotedFileMessage';
import { RetrieveMessageType } from '../../../../types/dto/message';

export function QuotedMessage({
  quotedMessage,
  scrollToMessage,
}: {
  quotedMessage: Message;
  scrollToMessage: (messageId: string) => void;
}) {
  return (
    <Box
      sx={{ pl: 1, borderLeft: `4px solid ${theme.palette.primary.light}`, backgroundColor: '#e8e8e8' }}
      onClick={() => scrollToMessage(quotedMessage.id)}>
      {quotedMessage.type === RetrieveMessageType.FILE ? (
        <QuotedFileMessage quotedMessage={quotedMessage} />
      ) : (
        quotedMessage.text
      )}
    </Box>
  );
}
