import { endpoints } from '../api';
import { call, put, select, takeLatest } from 'typed-redux-saga/macro';
import { requestActionBundles, setPatientDetails } from './action';
import { selectCurrentPatientIdOrThrow } from '../patient-list/selector';
import { selectOrganizationId } from './selector';
import { convertPatientDetails } from './convertUtils';
import { heurekaRequestActionBundles } from '../heureka/actions';

function* requestSaga() {
  try {
    const organizationId = yield* select(selectOrganizationId);
    const patientId = yield* select(selectCurrentPatientIdOrThrow);
    const { data } = yield* call(endpoints.patient.fetchOne, organizationId, patientId);
    const patient = convertPatientDetails(data);

    yield* put(setPatientDetails(patient));
    yield* put(requestActionBundles.fetchPatientDetails.requestSucceeded());

    if (patient.heurekaId) {
      yield* put(heurekaRequestActionBundles.fetchMedications.sendRequest());
    }
  } catch (error) {
    console.error('fetch patient details request failed', error);
    yield* put(requestActionBundles.fetchPatientDetails.requestFailed());
  }
}

export function* patientDetailsFetchSaga() {
  yield* takeLatest(requestActionBundles.fetchPatientDetails.sendRequest, requestSaga);
}
