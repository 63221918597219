import { createReducer } from 'deox';
import { addMessages, clearThumbnailUrls, setTextForChat, addThumbnailUrl } from './action';
import produce from 'immer';
import { Message } from '../../types/message';
import { last } from 'ramda';

const initialState: Readonly<{
  texts: { [patientId: string]: string };
  messages: { [patientId: string]: Message[] };
  thumbnailUrls: { [messageId: string]: string };
}> = {
  texts: {},
  messages: {},
  thumbnailUrls: {},
};

export const chatsReducer = createReducer(initialState, (handleAction) => [
  handleAction(setTextForChat, (state, { payload: { patientId, text } }) =>
    produce(state, (draft) => {
      draft.texts[patientId] = text;
    }),
  ),
  handleAction(addThumbnailUrl, (state, { payload: { messageId, thumbnailUrl } }) =>
    produce(state, (draft) => {
      draft.thumbnailUrls[messageId] = thumbnailUrl;
    }),
  ),
  handleAction(clearThumbnailUrls, (state, { payload: { patientId } }) =>
    produce(state, (draft) => {
      state.messages[patientId]?.forEach(({ id }) => {
        delete draft.thumbnailUrls[id];
      });
    }),
  ),
  handleAction(addMessages, (state, { payload: { patientId, messages } }) =>
    produce(state, (draft) => {
      if (state.messages[patientId]) {
        const lastMessageId = last(state.messages[patientId])?.id;
        const slicePosition = messages.findIndex(({ id }) => id === lastMessageId) + 1;
        draft.messages[patientId] = state.messages[patientId].concat(messages.slice(slicePosition));
      } else {
        draft.messages[patientId] = messages;
      }
    }),
  ),
]);
