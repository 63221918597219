import { isSameDay, startOfDay } from 'date-fns';
import { Message } from '../../types/message';

export const convertMessagesIntoChatItems = (messages: Message[]) =>
  messages.flatMap((message, index) => {
    const previousMessage = messages[index - 1];
    const addDayItemAbove = !previousMessage || !isSameDay(previousMessage.authoredDate, message.authoredDate);
    const showTail = addDayItemAbove || message.sentByPatient !== previousMessage.sentByPatient;
    const itemsAbove = addDayItemAbove ? [{ type: 'day' as const, date: startOfDay(message.authoredDate) }] : [];
    return [...itemsAbove, { type: 'message' as const, message, showTail }];
  });
