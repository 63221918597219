import { createTheme } from '@mui/material';

const green = {
  50: '#F2FAF4',
  100: '#E5F5E9',
  200: '#C0E7CA',
  300: '#9AD6A9',
  400: '#75BD87',
  500: '#57A26A',
  600: '#438955',
  700: '#2B733D',
  800: '#115A23',
  900: '#0D4A1C',
};

export default createTheme({
  typography: {
    fontFamily: ['"Inter"', '"Helvetica"', '"Arial"', 'sans-serif'].join(', '),
  },
  palette: {
    background: {
      default: 'hsl(0, 0%, 90%)',
    },
    primary: green,
    green,
    grey: {
      50: '#F8FBFA',
      100: '#F2F7F5',
      200: '#E9F0ED',
      300: '#D6E1DC',
      400: '#A4B8B0',
      500: '#738B81',
      600: '#50695F',
      700: '#3C554A',
      800: '#213B30',
      900: '#0F2A1F',
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 30,
          fontWeight: 600,
        },
      },
    },
  },
});
