import { RootState } from '../../../store';
import { Action, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { requestActionBundles } from '../../../store/data/action';
import { pipe } from 'ramda';
import { selectTextForChat } from '../../../store/chats/selector';
import { setTextForChat } from '../../../store/chats/action';
import { ChatInput } from './ChatInput';

const mapStateToProps = (state: RootState) => ({
  text: selectTextForChat(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setText: pipe(setTextForChat, dispatch),
  sendMessage: pipe(requestActionBundles.sendMessage.sendRequest, dispatch),
});

type OwnProps = {
  files: File[];
  addFiles: (files: File[]) => unknown;
  clearFiles: () => unknown;
  currentPatientId: string;
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
export type Props = Readonly<OwnProps & StateProps & DispatchProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ChatInput);
