import { Autocomplete, Box, Button, Stack, TextField } from '@mui/material';
import { CheckCircleOutlined } from '@mui/icons-material';
import { useState } from 'react';
import { LinkHeurekaToPatientDTO } from '../../types/dto/patient';
import { CompletePatient } from '../../types/patients';
import { HeurekaPatientsDTO } from '../../types/dto/heurekaPatient';

type AutocompleteOptions = { label: string; heurekaId: string } | undefined | null;

export default function LinkPatient({
  heurekaPatients,
  patientDetails,
  searchHeurekaPatients,
  linkHeurekaIdToPatient,
}: {
  readonly heurekaPatients: HeurekaPatientsDTO;
  patientDetails: CompletePatient | undefined;
  searchHeurekaPatients: (searchString: string) => void;
  linkHeurekaIdToPatient: (patientLinkDTO: LinkHeurekaToPatientDTO) => void;
}) {
  const [value, setValue] = useState<AutocompleteOptions>(null);

  return (
    <Stack spacing={2}>
      <Autocomplete
        value={value}
        onChange={(_event, newValue: AutocompleteOptions) => {
          setValue(newValue);
        }}
        onInputChange={(_event, newInputValue) => {
          searchHeurekaPatients(newInputValue);
        }}
        options={heurekaPatients.map((hp) => {
          return {
            label: `${hp.firstName} ${hp.lastName}`,
            heurekaId: hp.heurekaId,
          };
        })}
        isOptionEqualToValue={(option, value) =>
          option != null && value != null && option.heurekaId === value.heurekaId
        }
        renderInput={(params) => <TextField {...params} label="Patient suchen" />}
        forcePopupIcon={false}
        noOptionsText={'Keine Patienten gefunden. Sie können nach Vor- und Nachnamen suchen.'}
      />
      <Box gap={1} display="flex" justifyContent="end" flexWrap="wrap">
        <Button
          variant="contained"
          startIcon={<CheckCircleOutlined />}
          onClick={() => {
            if (value != null && patientDetails) {
              linkHeurekaIdToPatient({ heurekaId: value.heurekaId });
            }
          }}>
          Speichern
        </Button>
      </Box>
    </Stack>
  );
}
