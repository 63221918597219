import { put, takeLatest } from 'typed-redux-saga/macro';
import { requestActionBundles, setOrganizationId, startListeningForChatNotifications } from './action';
import { getType } from 'deox';

function* initialDispatchSaga() {
  yield* put(requestActionBundles.fetchPatients.sendRequest());
  yield* put(requestActionBundles.fetchOrganization.sendRequest());
  yield* put(startListeningForChatNotifications());
}

export function* initialSaga() {
  yield* takeLatest(getType(setOrganizationId), initialDispatchSaga);
}
