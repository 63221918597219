import { FileMessage as FileMessageType } from '../../../types/message';
import DownloadButton from './shared/DownloadButtonContainer';
import FileMessageImage from './FileMessageImageContainer';
import MessageBase from './shared/MessageBase';
import Time from './shared/Time';
import { MimeType } from '../../../types/mimeType';

const MIME_TYPES_TO_DISPLAY_AS_IMAGE = [MimeType.IMAGE_JPEG, MimeType.IMAGE_PNG, MimeType.IMAGE_GIF];

export default function FileMessage({
  message,
  showTail,
  isLast,
}: {
  message: FileMessageType;
  showTail: boolean;
  isLast: boolean;
}) {
  const { text, fileMimeType, sentByPatient } = message;

  if (MIME_TYPES_TO_DISPLAY_AS_IMAGE.includes(fileMimeType)) {
    return <FileMessageImage message={message} showTail={showTail} isLast={isLast} />;
  }

  return (
    <MessageBase sentByPatient={sentByPatient} showTail={showTail} isLast={isLast}>
      <div>
        <DownloadButton message={message} />
        <div>
          {text}
          <Time dateTime={message.authoredDate} />
        </div>
      </div>
    </MessageBase>
  );
}
