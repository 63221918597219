import { useEffect, useState } from 'react';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Divider, IconButton, styled } from '@mui/material';
import theme from '../../../theme';

const FileEditorContainer = styled(Box)`
  flex: 1;
  flex-direction: column;
  background-color: ${theme.palette.grey[200]};
`;

const FileEditorTopBar = styled(Box)`
  display: flex;
  justify-content: right;
  background: white;
`;

const FileEditorPreview = styled(Box)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    max-width: 80%;
    max-height: 500px;
    height: 50vh;
    object-fit: contain;
  }
`;

const FileEditorBottomBar = styled(Box)`
  justify-content: center;
  display: flex;

  & > * {
    height: 50px;
    width: 50px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }

  & > svg {
    padding: 6px;
  }

  & > img {
    object-fit: cover;
  }

  & > label {
    overflow: hidden;
    background: white;

    > span {
      height: 100%;
      width: 100%;
      border-radius: 0;
    }
  }
`;

type FileEditorProps = {
  files: File[];
  clearFiles: () => unknown;
  visible: boolean;
};

const fileIdentifier = (file: File) => file.lastModified + file.size;

export default function FileEditor({ files, clearFiles, visible }: FileEditorProps) {
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [imgUrls, setImgUrls] = useState<{ [id: number]: string }>({});

  useEffect(() => {
    setImgUrls((imgUrls) =>
      Object.fromEntries(
        files.map((file) => [fileIdentifier(file), imgUrls[fileIdentifier(file)] ?? URL.createObjectURL(file)]),
      ),
    );
  }, [files]);

  return (
    <FileEditorContainer display={visible ? 'flex' : 'none'}>
      <FileEditorTopBar p={1}>
        <IconButton aria-label="Abbrechen" component="span" onClick={clearFiles}>
          <ClearIcon />
        </IconButton>
      </FileEditorTopBar>
      <Divider />
      <FileEditorPreview p={1}>
        {files[currentFileIndex] &&
          (files[currentFileIndex].type.startsWith('image/') ? (
            <img src={imgUrls[fileIdentifier(files[currentFileIndex])]} alt="Vorschau Datei gross" />
          ) : (
            <div>Keine Vorschau</div>
          ))}
      </FileEditorPreview>
      <FileEditorBottomBar mb={2} gap={2}>
        {files.map((file, index) =>
          file.type.startsWith('image/') ? (
            <img
              key={index}
              src={imgUrls[fileIdentifier(file)]}
              alt="Vorschau Datei"
              onClick={() => setCurrentFileIndex(index)}
            />
          ) : (
            <FilePresentIcon key={index} onClick={() => setCurrentFileIndex(index)} />
          ),
        )}
        <label htmlFor="chat-add-file">
          <IconButton color="primary" aria-label="Datei hinzufügen" component="span">
            <AddIcon />
          </IconButton>
        </label>
      </FileEditorBottomBar>
    </FileEditorContainer>
  );
}
