import { Message } from '../../../types/message';
import FileMessage from './FileMessage';
import TextMessage from './TextMessage';
import { RetrieveMessageType } from '../../../types/dto/message';

export default function GenericMessage({
  message,
  showTail,
  scrollToMessage,
  isLast,
}: {
  message: Message;
  showTail: boolean;
  scrollToMessage: (messageId: string) => void;
  isLast: boolean;
}) {
  switch (message.type) {
    case RetrieveMessageType.TEXT:
      return <TextMessage message={message} showTail={showTail} isLast={isLast} scrollToMessage={scrollToMessage} />;
    case RetrieveMessageType.FILE:
      return <FileMessage message={message} showTail={showTail} isLast={isLast} />;
  }
}
