import { RootState } from '../../../../store';
import { connect } from 'react-redux';
import { selectOrganizationId, selectPatientDetails } from '../../../../store/data/selector';
import { selectCurrentPatientId } from '../../../../store/patient-list/selector';
import DownloadButton from './DownloadButton';
import { FileMessage } from '../../../../types/message';

const mapStateToProps = (state: RootState) => ({
  organizationId: selectOrganizationId(state),
  patientId: selectCurrentPatientId(state),
  patientDetails: selectPatientDetails(state),
});

type OwnProps = {
  message: FileMessage;
  Component?: (value: {
    download: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>;
    isDownloading: boolean;
    icon: JSX.Element;
  }) => JSX.Element;
};
type StateProps = ReturnType<typeof mapStateToProps>;
export type Props = Readonly<OwnProps & StateProps>;

export default connect(mapStateToProps)(DownloadButton);
