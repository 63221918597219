import { DownloadForOffline, Downloading } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { useState } from 'react';
import { endpoints } from '../../../../store/api';
import { Props } from './DownloadButtonContainer';
import { getFileName } from '../../../../utils/getFileName';
import { downloadURL } from '../../../../utils/downloadURL';

export default function DownloadButton({ organizationId, patientId, message, patientDetails, Component }: Props) {
  const { id } = message;
  const fileName = getFileName(message, patientDetails);
  const [isDownloading, setIsDownloading] = useState(false);

  if (!patientId) {
    return null;
  }

  const download = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setIsDownloading(true);
    const { data } = await endpoints.message.fetchFile(organizationId, patientId, id);
    const objectUrl = URL.createObjectURL(data);
    downloadURL(objectUrl, fileName);
    setIsDownloading(false);
    setTimeout(() => URL.revokeObjectURL(objectUrl), 60 * 1000);
  };

  const icon = isDownloading ? <Downloading /> : <DownloadForOffline />;

  if (Component) {
    return <Component download={download} isDownloading={isDownloading} icon={icon} />;
  }
  return (
    <Button
      variant="text"
      size="small"
      startIcon={icon}
      onClick={download}
      title={fileName}
      sx={{
        maxWidth: 330,
        justifyContent: 'start',
      }}>
      <Box
        display="inline"
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}>
        {fileName}
      </Box>
    </Button>
  );
}
