import { createActionCreator } from 'deox';

export const setCurrentPatientId = createActionCreator(
  'patientList/SET_CURRENT_PATIENT_ID',
  (resolve) => (patientId: string) => resolve({ patientId }),
);

export const setPatientSearchText = createActionCreator(
  'patientList/SET_PATIENT_SEARCH_TEXT',
  (resolve) => (searchText: string) => resolve({ searchText }),
);
