import { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Props } from './PatientDetailsContainer';
import EditPatientDetails from './EditPatientDetails';
import theme from '../../theme';
import { EditOutlined } from '@mui/icons-material';
import { format } from '../../utils/date';
import { InformationRow } from '../InformationRow';

export default function PatientDetails({ patientDetails, patchPatientDetails }: Props) {
  const [isEditing, setIsEditing] = useState(!patientDetails?.isConfirmed);

  useEffect(() => {
    if (patientDetails) {
      setIsEditing(!patientDetails.isConfirmed);
    }
  }, [patientDetails, patientDetails?.pid]);

  if (!patientDetails) return null;

  return (
    <Box pt={3} pr={3} pl={3}>
      <Box display="flex" alignItems="center" mb={2} height={40}>
        <Typography variant="subtitle2" sx={{ color: theme.palette.grey[800], fontWeight: 400, opacity: 0.75 }}>
          Patienteninformationen
        </Typography>
      </Box>
      {patientDetails &&
        (isEditing && !patientDetails.heurekaId ? (
          <EditPatientDetails
            key={patientDetails.pid}
            patientDetails={patientDetails}
            patchPatientDetails={patchPatientDetails}
            stopEditing={() => setIsEditing(false)}
          />
        ) : (
          <>
            <Typography variant="h6" sx={{ fontWeight: 600, fontSize: 17, mb: 2 }}>
              {patientDetails.firstName} {patientDetails.lastName}{' '}
              {patientDetails.dateOfBirth && `(${format(new Date(patientDetails.dateOfBirth), 'dd.MM.y')})`}
            </Typography>
            <InformationRow>
              <div>PID</div>
              <div>{patientDetails.pid}</div>
            </InformationRow>
            <InformationRow>
              <div>Threema ID</div>
              <div>{patientDetails.threemaId}</div>
            </InformationRow>
            {!patientDetails.heurekaId && (
              <Box display="flex" justifyContent="end" mt={4}>
                <Button onClick={() => setIsEditing(true)} variant="outlined" startIcon={<EditOutlined />}>
                  Patient bearbeiten
                </Button>
              </Box>
            )}
          </>
        ))}
    </Box>
  );
}
