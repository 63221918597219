import { DragEvent, useEffect, useMemo, useState } from 'react';

export const useDropzone = (onFiles: (files: File[]) => unknown) => {
  const [showDropzone, setShowDropzone] = useState(false);

  useEffect(() => {
    const hideDropzone = () => setShowDropzone(false);
    document.documentElement.addEventListener('mouseleave', hideDropzone);
    return () => document.documentElement.removeEventListener('mouseleave', hideDropzone);
  }, []);

  const dropzoneProps = useMemo(
    () => ({
      onDrop: (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();

        if (event.dataTransfer.files.length) {
          onFiles(Array.from(event.dataTransfer.files));
        }

        setShowDropzone(false);
      },
      onDragOver: (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setShowDropzone(true);
      },
    }),
    [onFiles],
  );

  return [showDropzone, dropzoneProps] as const;
};
