import { Accordion, AccordionDetails, AccordionProps, AccordionSummary, styled, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getMedicationDuration, Medication } from '../../types/medication';
import MedicationDetails from './MedicationDetails';
import theme from '../../theme';

// CSS rules with the usual styled syntax were not applied somehow this is why the following is used:
const CustomAccordion = styled((props: AccordionProps) => <Accordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.green[200]}`,
    '&:last-child': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }),
);

const CustomAccordionSummary = styled(AccordionSummary)`
  color: ${theme.palette.grey[700]};
  padding: 0px;
`;

const CustomAccordionDetails = styled(AccordionDetails)`
  color: ${theme.palette.grey[700]};
`;

export default function MedicationAccordion({ medication }: { medication: Medication }) {
  const details = [
    [getMedicationDuration(medication), 'Dauer von - bis:'],
    [medication.dosingSchedule, 'Einnahmeschema (Mo-Mi-Ab-Na):'],
    [medication.commentOnDosingSchedule, 'Bemerkung Einnahmeschema:'],
    [medication.comment, 'Bemerkung allgemein:'],
  ] as const;
  return (
    <CustomAccordion>
      <CustomAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{medication.medicationName}</Typography>
      </CustomAccordionSummary>
      <CustomAccordionDetails>
        {details.map(([details, title]) => (
          <MedicationDetails key={title} title={title} details={details} />
        ))}
      </CustomAccordionDetails>
    </CustomAccordion>
  );
}
