import { Virtuoso } from 'react-virtuoso';
import PatientDetailsContainer from '../PatientDetails/PatientDetailsContainer';
import HeurekaConnectorContainer from '../Heureka/HeurekaConnectorContainer';
import { Props } from './SidebarContainer';

export default function Sidebar({ patientDetails, organization }: Props) {
  const heurekaConnector = !!organization?.heurekaEnabled ? [<HeurekaConnectorContainer />] : [];
  const sidebar = patientDetails ? [<PatientDetailsContainer />, ...heurekaConnector] : heurekaConnector;
  return <Virtuoso data={sidebar} itemContent={(index, item) => item}></Virtuoso>;
}
