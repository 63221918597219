import { Box, IconButton, TextField } from '@mui/material';
import { Send } from '@mui/icons-material';
import { ChangeEvent, useCallback, useEffect, useRef } from 'react';
import { Props } from './ChatInputContainer';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import styled from '@emotion/styled';
import theme from '../../../theme';

const AddFileInput = styled.input`
  display: none;
`;

const FileButton = styled(IconButton)`
  &:focus-visible {
    background: ${theme.palette.grey[200]};
  }
`;

const TextInput = styled(TextField)`
  flex: 1;
`;

const SendButton = styled(IconButton)`
  background-color: ${theme.palette.primary.main};
  color: ${theme.palette.primary.contrastText};

  &.Mui-disabled {
    background-color: ${theme.palette.action.disabledBackground};
    color: ${theme.palette.action.disabled};
    opacity: 0.6;
  }

  &:hover,
  &:focus-visible {
    background-color: ${theme.palette.primary.dark};
  }
`;

export function ChatInput({ currentPatientId, text, setText, sendMessage, files, addFiles, clearFiles }: Props) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const textInputRef = useRef<HTMLTextAreaElement>();
  useEffect(() => textInputRef.current?.focus(), [currentPatientId]);

  const onFile = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        addFiles(Array.from(event.target.files));
      }
    },
    [addFiles],
  );

  const onSend = useCallback(() => {
    sendMessage(files);
    clearFiles(); // TODO: better recovery
    textInputRef.current?.focus();
  }, [clearFiles, files, sendMessage]);

  const sendMessageDisabled = !text && !files.length;

  return (
    <Box py={2} px={2} gap={2} display="flex" alignItems="end" bgcolor="white">
      <div>
        <AddFileInput id="chat-add-file" type="file" onChange={onFile} ref={fileInputRef} />
        <FileButton size="large" aria-label="Datei hinzufügen" onClick={() => fileInputRef.current?.click()}>
          <AttachFileIcon />
        </FileButton>
      </div>
      <TextInput
        disabled={!currentPatientId}
        onChange={(event) => setText(currentPatientId, event.target.value)}
        value={text}
        multiline
        maxRows={10}
        inputRef={textInputRef}
        InputProps={{ sx: { py: '12.5px' } }}
      />
      <SendButton size="large" color="primary" type="submit" disabled={sendMessageDisabled} onClick={onSend}>
        <Send />
      </SendButton>
    </Box>
  );
}
