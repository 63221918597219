import axios, { AxiosProgressEvent, AxiosResponse } from 'axios';
import { getToken } from '../keycloak';
import {
  ConnectHeurekaResponseDTO,
  LinkHeurekaToPatientDTO,
  PatientDetailsDTO,
  PatientMutationDTO,
  PatientsDTO,
} from '../types/dto/patient';
import { RetrieveFileMessageDTO, RetrieveMessagesDTO, RetrieveTextMessageDTO } from '../types/dto/message';
import { OrganizationDTO } from '../types/dto/organization';
import { HeurekaPatientsDTO } from '../types/dto/heurekaPatient';
import { Medication } from '../types/medication';

axios.interceptors.request.use(async (config) => {
  config.headers.Authorization = `Bearer ${await getToken()}`;
  return config;
});

const getBaseOrganizationUrl = (organizationId: string) => `/api/organization/${organizationId}`;

const getBasePatientUrl = (organizationId: string, patientId = '') =>
  `/api/organization/${organizationId}/patient/${patientId}`;

export const endpoints = {
  organization: {
    fetchOne: (organizationId: string) => axios.get<OrganizationDTO>(getBaseOrganizationUrl(organizationId)),
  },
  heurekaPatient: {
    fetchAll: (organizationId: string, searchString: string) =>
      axios.get<{ items: HeurekaPatientsDTO }>(
        `${getBaseOrganizationUrl(organizationId)}/heureka/patients?searchString=${searchString}`,
      ),
    connect: (
      organizationId: string,
      code?: string,
      nonce?: string,
    ): Promise<AxiosResponse<ConnectHeurekaResponseDTO>> => {
      return axios.post<ConnectHeurekaResponseDTO>(
        `${getBaseOrganizationUrl(organizationId)}/heureka/connect` +
          (code ? `?code=${code}` : '') +
          (nonce ? `&nonce=${nonce}` : ''),
      );
    },
  },
  patient: {
    fetchOne: (organizationId: string, patientId: string) =>
      axios.get<PatientDetailsDTO>(getBasePatientUrl(organizationId, patientId)),
    fetchAll: (organizationId: string) => axios.get<{ items: PatientsDTO }>(getBasePatientUrl(organizationId)),
    patch: (organizationId: string, patientId: string, dto: PatientMutationDTO) =>
      axios.patch<PatientDetailsDTO>(getBasePatientUrl(organizationId, patientId), dto),
    linkHeureka: (organizationId: string, patientId: string, dto: LinkHeurekaToPatientDTO) =>
      axios.patch<PatientDetailsDTO>(`${getBasePatientUrl(organizationId, patientId)}/heureka`, dto),
  },
  medication: {
    fetchAll: (organizationId: string, patientId: string) =>
      axios.get<{ items: Medication[] }>(
        `${getBaseOrganizationUrl(organizationId)}/heureka/medication-statements?patientId=${patientId}`,
      ),
  },
  message: {
    fetchAll: (organizationId: string, patientId: string) =>
      axios.get<{ items: RetrieveMessagesDTO }>(`${getBasePatientUrl(organizationId, patientId)}/message`),
    sendText: (organizationId: string, patientId: string, text: string) =>
      axios.post<RetrieveTextMessageDTO>(`${getBasePatientUrl(organizationId, patientId)}/message/text`, { text }),
    sendFile: (organizationId: string, patientId: string, formData: FormData) =>
      axios.post<RetrieveFileMessageDTO>(`${getBasePatientUrl(organizationId, patientId)}/message/file`, formData),
    fetchFile: (
      organizationId: string,
      patientId: string,
      messageId: string,
      onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void,
      controller?: AbortController,
    ) =>
      axios.get<Blob>(`${getBasePatientUrl(organizationId, patientId)}/message/${messageId}/file`, {
        responseType: 'blob',
        onDownloadProgress,
        signal: controller?.signal,
      }),
    fetchThumbnail: (organizationId: string, patientId: string, messageId: string) =>
      axios.get<Blob>(`${getBasePatientUrl(organizationId, patientId)}/message/${messageId}/file?thumbnail`, {
        responseType: 'blob',
      }),
  },
};
