import { styled } from '@mui/material';
import theme from '../theme';

export const InformationRow = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.palette.green[200]};
  gap: ${theme.spacing(0, 1)};
  padding: ${theme.spacing(1, 0)};
  & > :first-of-type {
    color: ${theme.palette.grey[500]};
  }
  & > :last-of-type {
    font-weight: 500;
    color: ${theme.palette.grey[700]};
  }
`;
