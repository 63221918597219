import { TextMessage as TextMessageType } from '../../../types/message';
import MessageBase from './shared/MessageBase';
import { Box } from '@mui/material';
import Time from './shared/Time';
import { QuotedMessage } from './QuotedMessage/QuotedMessage';
import { createMarkupText } from '../../../utils/markupText';

export default function TextMessage({
  message,
  showTail,
  scrollToMessage,
  isLast,
}: {
  message: TextMessageType;
  showTail: boolean;
  scrollToMessage: (messageId: string) => void;
  isLast: boolean;
}) {
  const { text, authoredDate, sentByPatient, quotedMessage } = message;

  return (
    <MessageBase sentByPatient={sentByPatient} showTail={showTail} sx={{ maxWidth: '65%' }} isLast={isLast}>
      <Box>
        {quotedMessage && <QuotedMessage quotedMessage={quotedMessage} scrollToMessage={scrollToMessage} />}
        {createMarkupText(text)}
        <Time dateTime={authoredDate} />
      </Box>
    </MessageBase>
  );
}
