import { MimeType } from '../types/mimeType';

export const MIME_TYPES_TO_EXTENSION = new Map([
  [MimeType.IMAGE_JPEG, 'jpg'],
  [MimeType.IMAGE_PNG, 'png'],
  [MimeType.IMAGE_GIF, 'gif'],
  [MimeType.IMAGE_HEIF, 'heif'],
  [MimeType.IMAGE_HEIC, 'heic'],
  [MimeType.IMAGE_TIFF, 'tiff'],
  [MimeType.VIDEO_MPEG, 'mpeg'],
  [MimeType.VIDEO_MP4, 'mp4'],
  [MimeType.VIDEO_AVC, 'avc'],
  [MimeType.AUDIO_AAC, 'aac'],
  [MimeType.AUDIO_MIDI, 'midi'],
  [MimeType.AUDIO_XMIDI, 'midi'],
  [MimeType.AUDIO_FLAC, 'flac'],
  [MimeType.AUDIO_XFLAC, 'flac'],
  [MimeType.APPLICATION_ZIP, 'zip'],
  [MimeType.APPLICATION_PDF, 'pdf'],
  [MimeType.TEXT_X_VCARD, 'vcf'],
  [MimeType.TEXT_VCARD, 'vcf'],
  [MimeType.TEXT_PLAIN, 'txt'],
  [MimeType.TEXT_HTML, 'html'],
  [MimeType.APPLICATION_OCTETSTREAM, ''],
]);
