import { endpoints } from '../api';
import { ActionType } from 'deox';
import { call, put, select, takeLatest } from 'typed-redux-saga/macro';
import { selectOrganizationId } from './selector';
import { heurekaRequestActionBundles } from '../heureka/actions';

function* requestSaga({ payload }: ActionType<typeof heurekaRequestActionBundles.connectHeureka.sendRequest>) {
  try {
    const organizationId = yield* select(selectOrganizationId);

    const { data } = yield* call(endpoints.heurekaPatient.connect, organizationId, payload.code, payload.nonce);

    if (data.authorizationUrl) {
      const parsedAuthorizationUrl = new URL(data.authorizationUrl);
      localStorage.setItem('state', parsedAuthorizationUrl.searchParams.get('state') ?? '');
      localStorage.setItem('nonce', parsedAuthorizationUrl.searchParams.get('nonce') ?? '');
      window.location.replace(parsedAuthorizationUrl);
    } else {
      window.location.replace('/#');
    }

    yield* put(heurekaRequestActionBundles.connectHeureka.requestSucceeded());
  } catch (error) {
    console.error('send message request failed', error);
    yield* put(heurekaRequestActionBundles.connectHeureka.requestFailed());
  }
}

export function* connectHeurekaSaga() {
  yield* takeLatest(heurekaRequestActionBundles.connectHeureka.sendRequest, requestSaga);
}
