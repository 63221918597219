import { Box, Divider } from '@mui/material';
import { isToday, isYesterday } from 'date-fns';
import { format } from '../../utils/date';
import theme from '../../theme';

export default function DateBubble({ date }: { date: Date }) {
  return (
    <Box pb={1} pt={2} mx={4}>
      <Divider>
        <Box sx={{ fontSize: 14, lineHeight: '20px', color: theme.palette.grey[800] }}>{formatDate(date)}</Box>
      </Divider>
    </Box>
  );
}

function formatDate(date: Date) {
  if (isToday(date)) {
    return 'Heute';
  }
  if (isYesterday(date)) {
    return 'Gestern';
  }
  return format(date, 'd. MMMM yyyy');
}
