import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPatientId } from '../store/patient-list/action';
import { useEffect, useState } from 'react';
import { selectPatients } from '../store/data/selector';
import { CompactPatient } from '../types/patients';

export const useURLSearchParamsForAppConfiguration = () => {
  const dispatch = useDispatch();
  const patients = useSelector(selectPatients);
  const [params] = useState(new URLSearchParams(window.location.search));
  const [evaluated, setEvaluated] = useState(false);
  const [hidePatientList, setHidePatientList] = useState(params.has('hidePatientList'));

  const code = params.get('code');
  const state = params.get('state');

  useEffect(() => {
    if (!evaluated && patients.length) {
      let patient: CompactPatient | undefined;

      const patientId = params.get('patientId');
      if (patientId) {
        patient = patients.find(({ id }) => id === patientId);
      }

      const patientPid = params.get('patientPid');
      if (!patient && patientPid) {
        patient = patients.find(({ pid }) => pid === patientPid);
      }

      if (patient) {
        dispatch(setCurrentPatientId(patient.id));
      } else {
        setHidePatientList(false);
      }

      setEvaluated(true);
    }
  }, [dispatch, evaluated, params, patients]);

  return { hidePatientList, code, state };
};
