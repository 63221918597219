import { endpoints } from '../api';
import { ActionType } from 'deox';
import { call, put, select, takeLatest } from 'typed-redux-saga/macro';
import { selectCurrentPatientIdOrThrow } from '../patient-list/selector';
import { selectOrganizationId } from '../data/selector';
import { convertPatientDetails } from '../data/convertUtils';
import { heurekaRequestActionBundles, setHeurekaPatients } from './actions';
import { setPatientDetails } from '../data/action';

function* requestSaga({ payload }: ActionType<typeof heurekaRequestActionBundles.linkHeureka.sendRequest>) {
  try {
    const organizationId = yield* select(selectOrganizationId);
    const patientId = yield* select(selectCurrentPatientIdOrThrow);
    yield* put(setHeurekaPatients([]));
    const { data } = yield* call(endpoints.patient.linkHeureka, organizationId, patientId, payload);

    yield* put(setPatientDetails(convertPatientDetails(data)));
    yield* put(heurekaRequestActionBundles.linkHeureka.requestSucceeded());
  } catch (error) {
    console.error('send message request failed', error);
    yield* put(heurekaRequestActionBundles.linkHeureka.requestFailed());
  }
}

export function* linkHeurekaSaga() {
  yield* takeLatest(heurekaRequestActionBundles.linkHeureka.sendRequest, requestSaga);
}
