import { RootState } from '../../store';
import { connect } from 'react-redux';
import Chat from './Chat';
import { selectIsLoadingMessages } from '../../store/data/selector';
import { selectCurrentPatientId } from '../../store/patient-list/selector';
import { selectMessagesForChat } from '../../store/chats/selector';

const mapStateToProps = (state: RootState) => ({
  currentPatientId: selectCurrentPatientId(state),
  messages: selectMessagesForChat(state),
  isLoadingMessages: selectIsLoadingMessages(state),
});

type StateProps = ReturnType<typeof mapStateToProps>;
export type Props = Readonly<StateProps>;

export default connect(mapStateToProps)(Chat);
